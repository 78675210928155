import React from 'react'
import { DESKTOP, Flex, Heading, MOBILE, PRINT, SimpleMenu, XL } from 'chemistry'
import SearchInputContainer from 'client/fragments/softContentOverview/components/SearchInputContainer/SearchInputContainer'
import { getTranslator } from 'client/common/translator'

export const TopNavigation = ({
  pageTitle,
  format,
  lang,
  contentfulTranslations,
  filter,
  filters,
  translations,
  isListerSearch,
  setFiltersHandler,
  searchSubmitHandler
}) => {
  if (isListerSearch) return null
  const t = getTranslator(translations)
  return (
    <>
      <Heading as='h1' {...headerStyles}>
        {pageTitle}
      </Heading>

      <Flex
        sx={{
          justifyContent: 'space-between',
          ...printWrapper.sx,
          [MOBILE]: {
            flexDirection: 'column'
          }
        }}
      >
        <SearchInputContainer
          {...{
            format,
            lang,
            placeholderText: contentfulTranslations.searchPlaceholder ?? '',
            t,
            handleSearchSubmit: searchSubmitHandler
          }}
        />

        <Flex
          sx={{
            marginLeft: 'sx',
            marginTop: 0,
            alignItems: 'center',
            [MOBILE]: {
              marginLeft: 0,
              marginTop: 'sx'
            }
          }}
        >
          {filter?.items?.length > 1 && (
            <SimpleMenu
              isMultiple
              selected={filters}
              {...filter}
              selectHandler={items => setFiltersHandler([...items])}
            />
          )}
        </Flex>
      </Flex>
    </>
  )
}

const headerStyles = {
  sx: {
    letterSpacing: '0.25',
    lineHeight: '1',
    width: '100%',
    borderBottom: '1px solid',
    borderColor: 'grey.300',
    fontWeight: '700',
    [MOBILE]: {
      fontSize: '34px',
      marginBottom: '20px',
      paddingBottom: '20px'
    },
    [DESKTOP]: {
      fontSize: '48px',
      marginBottom: '24px',
      paddingBottom: '24px'
    },
    [XL]: {
      fontSize: '60px'
    }
  }
}

const printWrapper = {
  sx: {
    [PRINT]: {
      display: 'none'
    }
  }
}
