const { window } = global
export const isPageBusAvailable = () => {
  return typeof window !== 'undefined' && typeof window.pageBus !== 'undefined' && window.pageBus
}

export const dispatchPageBusEvent = action => {
  if (isPageBusAvailable()) {
    window.pageBus.dispatch(action)
  }
}

export const subscribe = (listenerId, type, listener) => {
  if (isPageBusAvailable()) {
    window.pageBus.subscribe(listenerId, type, listener)
  }
}

export const subscribeAndProcess = (listenerId, type, listener) => {
  if (isPageBusAvailable()) {
    window.pageBus.subscribeAndProcess(listenerId, type, listener)
  }
}

export const getPageBusEvents = () => {
  if (isPageBusAvailable()) {
    return window.pageBus.getEvents() || []
  }
}
