import axios from 'axios'
import { getOverviewResults } from '../../../common/xhr/gateway'
import { OVERVIEW_VIEW_SIZE } from 'common/constants'
import { dispatchPageBusEvent } from 'client/common/pageBus'
import throttle from '../../../common/throttle'

export const GET_PAGES = 'GET_PAGES'
export const SET_PAGES = 'SET_PAGES'
export const SET_PAGES_PAGINATION = 'SET_PAGES_PAGINATION'

const updateParam = (searchParams, param, value) => {
  if (searchParams.get(param) && value) {
    searchParams.set(param, value)
  } else if (value) {
    searchParams.append(param, value)
  } else {
    searchParams.delete(param)
  }
}

const updateParams = (searchParams, params) => {
  Object.keys(params).forEach(param => updateParam(searchParams, param, params[param]))
}

export const loadPages = (
  item,
  { isPaginated, format, lang, inclusionTags, exclusionTags, viewSize, pageTypes, allPageTypes, dispatchPages }
) =>
  throttle(
    () =>
      onLoadPages({
        ...item,
        isPaginated,
        format,
        lang,
        inclusionTags,
        exclusionTags,
        viewSize,
        pageTypes,
        allPageTypes,
        dispatchPages
      }),
    300
  )

export const onLoadPages = ({
  source,
  isPaginated,
  format,
  lang,
  sortBy = 'publishDateTime',
  sortOrder = 'descending',
  inclusionTags,
  exclusionTags,
  term,
  pageTypes,
  viewSize = OVERVIEW_VIEW_SIZE,
  page = 1,
  reset = true,
  allPageTypes,
  dispatchPages
} = {}) => {
  dispatchPages({
    type: GET_PAGES,
    page,
    reset
  })
  page = reset ? 1 : page
  const currentUrl = new URL(window.location.href)

  const params = { pageTypes: pageTypes?.length ? pageTypes.join(',') : '' }
  const isSearch = currentUrl.searchParams.get('text')
  if (!isSearch) params.term = term
  params.currentPage = page
  if (page === 1) params.currentPage = false
  updateParams(currentUrl.searchParams, params)

  global.window.history.pushState({ timestamp: new Date().getTime() }, '', currentUrl)
  const url = getOverviewResults({
    lang,
    format,
    sortBy,
    sortOrder,
    inclusionTags,
    exclusionTags,
    term,
    pageTypes: pageTypes?.length ? pageTypes : allPageTypes,
    viewSize,
    page
  })
  // if (page === 1) {
  //   const response = responseData.response || {}
  //
  //   dispatchPages({
  //     type: isPaginated ? SET_PAGES_PAGINATION : SET_PAGES,
  //     pages: response.results,
  //     totalNumberOfResults: response.totalNumberOfResults,
  //     page,
  //     reset
  //   })
  //   dispatchPageBusEvent({ type: 'SOFT_CONTENT_SEARCH_RENDERED', props: { count: response?.totalNumberOfResults } })
  //   return
  // } else {
  //   const response = responseData2.response || {}
  //
  //   dispatchPages({
  //     type: isPaginated ? SET_PAGES_PAGINATION : SET_PAGES,
  //     pages: response.results,
  //     totalNumberOfResults: response.totalNumberOfResults,
  //     page,
  //     reset
  //   })
  //   dispatchPageBusEvent({ type: 'SOFT_CONTENT_SEARCH_RENDERED', props: { count: response?.totalNumberOfResults } })
  //   return
  // }

  return axios
    .get(url)
    .then(resp => {
      const response = resp?.data?.response || {}
      dispatchPages({
        type: isPaginated ? SET_PAGES_PAGINATION : SET_PAGES,
        pages: response.results,
        totalNumberOfResults: response.totalNumberOfResults,
        page,
        reset
      })
      dispatchPageBusEvent({ type: 'SOFT_CONTENT_SEARCH_RENDERED', props: { count: response?.totalNumberOfResults } })
      return resp
    })
    .catch(e => {
      dispatchPages({
        type: SET_PAGES,
        pages: [],
        reset
      })
    })
}

// const responseData = {
//   resultId: 'd63a842b-b7ad-4541-9fe6-c2e9a133f6e3',
//   response: {
//     facets: [
//       {
//         displayName: 'valid',
//         name: 'valid',
//         options: [{ count: 412, data: {}, displayName: '1', status: 'selected', value: '1' }],
//         type: 'multiple',
//         data: {},
//         hidden: false
//       },
//       {
//         displayName: 'pageType',
//         name: 'pageType',
//         options: [
//           { count: 65, data: {}, displayName: 'product-advice', status: 'selected', value: 'product-advice' },
//           { count: 166, data: {}, displayName: 'step-by-step', status: 'selected', value: 'step-by-step' },
//           { count: 48, data: {}, displayName: 'diy-tip', status: 'selected', value: 'diy-tip' },
//           { count: 133, data: {}, displayName: 'blog', status: 'selected', value: 'blog' }
//         ],
//         type: 'multiple',
//         data: {},
//         hidden: false
//       },
//       {
//         displayName: 'tags',
//         name: 'tags',
//         options: [
//           { count: 95, data: {}, displayName: 'Garden', status: '', value: 'Garden' },
//           {
//             count: 69,
//             data: {},
//             displayName: 'customer journey tag shopping',
//             status: '',
//             value: 'customer journey tag shopping'
//           },
//           { count: 65, data: {}, displayName: 'Product advice', status: 'selected', value: 'Product advice' },
//           { count: 58, data: {}, displayName: 'Season summer', status: '', value: 'Season summer' },
//           { count: 57, data: {}, displayName: 'Season spring', status: '', value: 'Season spring' },
//           { count: 39, data: {}, displayName: 'Wood', status: '', value: 'Wood' },
//           { count: 39, data: {}, displayName: 'Sustainable', status: '', value: 'Sustainable' },
//           { count: 15, data: {}, displayName: 'Garden wood', status: '', value: 'Garden wood' },
//           { count: 9, data: {}, displayName: 'garden-world', status: '', value: 'garden-world' },
//           { count: 166, data: {}, displayName: 'Step-by-Step', status: 'selected', value: 'Step-by-Step' },
//           { count: 102, data: {}, displayName: 'Practical', status: '', value: 'Practical' },
//           { count: 12, data: {}, displayName: 'Window', status: '', value: 'Window' },
//           { count: 2, data: {}, displayName: 'Screens', status: '', value: 'Screens' },
//           { count: 31, data: {}, displayName: 'Bathroom', status: '', value: 'Bathroom' },
//           { count: 6, data: {}, displayName: 'Shower', status: '', value: 'Shower' },
//           { count: 3, data: {}, displayName: 'Faucets', status: '', value: 'Faucets' },
//           { count: 2, data: {}, displayName: 'Showerset', status: '', value: 'Showerset' },
//           { count: 8, data: {}, displayName: 'Bathroom furniture', status: '', value: 'Bathroom furniture' },
//           { count: 5, data: {}, displayName: 'Fence', status: '', value: 'Fence' },
//           { count: 19, data: {}, displayName: 'Window decoration', status: '', value: 'Window decoration' },
//           { count: 11, data: {}, displayName: 'Doors', status: '', value: 'Doors' },
//           { count: 2, data: {}, displayName: 'Screendoor', status: '', value: 'Screendoor' },
//           { count: 16, data: {}, displayName: 'Sanitary', status: '', value: 'Sanitary' },
//           { count: 12, data: {}, displayName: 'Tiles', status: '', value: 'Tiles' },
//           { count: 3, data: {}, displayName: 'Wall tiles', status: '', value: 'Wall tiles' },
//           { count: 27, data: {}, displayName: 'Plants', status: '', value: 'Plants' },
//           { count: 4, data: {}, displayName: 'Potting soil', status: '', value: 'Potting soil' },
//           { count: 13, data: {}, displayName: 'Electra', status: '', value: 'Electra' },
//           { count: 5, data: {}, displayName: 'Roofs', status: '', value: 'Roofs' },
//           { count: 2, data: {}, displayName: 'Gutter', status: '', value: 'Gutter' },
//           { count: 4, data: {}, displayName: 'Stairs', status: '', value: 'Stairs' },
//           { count: 2, data: {}, displayName: 'Stair renovation', status: '', value: 'Stair renovation' },
//           { count: 12, data: {}, displayName: 'Sunscreens', status: '', value: 'Sunscreens' },
//           { count: 48, data: {}, displayName: 'DIY-tip', status: 'selected', value: 'DIY-tip' },
//           { count: 3, data: {}, displayName: 'Glue and sealant', status: '', value: 'Glue and sealant' },
//           { count: 6, data: {}, displayName: 'Wallpaper', status: '', value: 'Wallpaper' },
//           { count: 133, data: {}, displayName: 'Blog page type', status: 'selected', value: 'Blog page type' },
//           { count: 18, data: {}, displayName: 'Lighting', status: '', value: 'Lighting' },
//           { count: 17, data: {}, displayName: 'Season fall', status: '', value: 'Season fall' },
//           { count: 4, data: {}, displayName: 'Lamps', status: '', value: 'Lamps' },
//           { count: 3, data: {}, displayName: 'LED lamps', status: '', value: 'LED lamps' },
//           { count: 41, data: {}, displayName: 'Paint', status: '', value: 'Paint' },
//           { count: 6, data: {}, displayName: 'paint-stain', status: '', value: 'paint-stain' },
//           { count: 5, data: {}, displayName: 'Paint-Lacquer', status: '', value: 'Paint-Lacquer' },
//           { count: 4, data: {}, displayName: 'paint-varnish', status: '', value: 'paint-varnish' },
//           { count: 4, data: {}, displayName: 'paint-types', status: '', value: 'paint-types' },
//           { count: 4, data: {}, displayName: 'paint-primer', status: '', value: 'paint-primer' },
//           { count: 4, data: {}, displayName: 'paint-metal', status: '', value: 'paint-metal' },
//           { count: 4, data: {}, displayName: 'paint-latex', status: '', value: 'paint-latex' },
//           { count: 4, data: {}, displayName: 'paint-alkyd', status: '', value: 'paint-alkyd' },
//           { count: 4, data: {}, displayName: 'paint-acrylic', status: '', value: 'paint-acrylic' },
//           { count: 10, data: {}, displayName: 'Toilet', status: '', value: 'Toilet' },
//           { count: 1, data: {}, displayName: 'Built-in toilets', status: '', value: 'Built-in toilets' },
//           { count: 24, data: {}, displayName: 'Floors', status: '', value: 'Floors' },
//           { count: 9, data: {}, displayName: 'PVC floor', status: '', value: 'PVC floor' },
//           { count: 15, data: {}, displayName: 'Kitchen', status: '', value: 'Kitchen' },
//           { count: 10, data: {}, displayName: 'Cooling', status: '', value: 'Cooling' },
//           { count: 8, data: {}, displayName: 'Maintenance', status: '', value: 'Maintenance' },
//           { count: 3, data: {}, displayName: 'Swimming pool', status: '', value: 'Swimming pool' },
//           { count: 3, data: {}, displayName: 'Airco', status: '', value: 'Airco' },
//           { count: 2, data: {}, displayName: 'Window film', status: '', value: 'Window film' },
//           { count: 1, data: {}, displayName: 'Shower tray', status: '', value: 'Shower tray' },
//           { count: 1, data: {}, displayName: 'Grouts', status: '', value: 'Grouts' },
//           { count: 11, data: {}, displayName: 'Laminate', status: '', value: 'Laminate' },
//           { count: 9, data: {}, displayName: 'Radiator', status: '', value: 'Radiator' },
//           { count: 7, data: {}, displayName: 'Construction materials', status: '', value: 'Construction materials' },
//           { count: 2, data: {}, displayName: 'Insulation', status: '', value: 'Insulation' },
//           { count: 15, data: {}, displayName: 'Walls', status: '', value: 'Walls' },
//           { count: 11, data: {}, displayName: 'Wall', status: '', value: 'Wall' },
//           { count: 2, data: {}, displayName: 'blinds', status: '', value: 'blinds' },
//           { count: 7, data: {}, displayName: 'Garden tools', status: '', value: 'Garden tools' },
//           { count: 1, data: {}, displayName: 'High-pressure cleaner', status: '', value: 'High-pressure cleaner' },
//           { count: 5, data: {}, displayName: 'Indoor lighting', status: '', value: 'Indoor lighting' },
//           { count: 6, data: {}, displayName: 'Security', status: '', value: 'Security' },
//           { count: 1, data: {}, displayName: 'Lock', status: '', value: 'Lock' },
//           { count: 15, data: {}, displayName: 'Painting', status: '', value: 'Painting' },
//           { count: 21, data: {}, displayName: 'Season winter', status: '', value: 'Season winter' },
//           { count: 8, data: {}, displayName: 'Insulation material', status: '', value: 'Insulation material' },
//           { count: 1, data: {}, displayName: 'Outside', status: '', value: 'Outside' },
//           { count: 1, data: {}, displayName: 'Skirting boards', status: '', value: 'Skirting boards' },
//           { count: 70, data: {}, displayName: 'Inspiration', status: '', value: 'Inspiration' },
//           { count: 27, data: {}, displayName: 'Balcony', status: '', value: 'Balcony' },
//           { count: 3, data: {}, displayName: 'Garden storage', status: '', value: 'Garden storage' },
//           { count: 2, data: {}, displayName: 'Drills', status: '', value: 'Drills' },
//           { count: 1, data: {}, displayName: 'Bathroom lighting', status: '', value: 'Bathroom lighting' },
//           { count: 5, data: {}, displayName: 'Garden furniture', status: '', value: 'Garden furniture' },
//           { count: 2, data: {}, displayName: 'Ceilings', status: '', value: 'Ceilings' },
//           { count: 9, data: {}, displayName: 'Storage', status: '', value: 'Storage' },
//           { count: 4, data: {}, displayName: 'Tidy up', status: '', value: 'Tidy up' },
//           { count: 3, data: {}, displayName: 'Attic', status: '', value: 'Attic' },
//           { count: 12, data: {}, displayName: 'Heating', status: '', value: 'Heating' },
//           { count: 1, data: {}, displayName: 'Sawing machine', status: '', value: 'Sawing machine' },
//           { count: 6, data: {}, displayName: 'Barbecue', status: '', value: 'Barbecue' },
//           { count: 11, data: {}, displayName: 'Lawn maintenance', status: '', value: 'Lawn maintenance' },
//           { count: 1, data: {}, displayName: 'Lawn mower', status: '', value: 'Lawn mower' },
//           { count: 2, data: {}, displayName: 'Weeds', status: '', value: 'Weeds' },
//           { count: 3, data: {}, displayName: 'Roller blind', status: '', value: 'Roller blind' },
//           { count: 2, data: {}, displayName: 'Windows', status: '', value: 'Windows' },
//           { count: 1, data: {}, displayName: 'Log cabin', status: '', value: 'Log cabin' },
//           { count: 1, data: {}, displayName: 'Garden shed', status: '', value: 'Garden shed' },
//           { count: 2, data: {}, displayName: 'Sanding machine', status: '', value: 'Sanding machine' },
//           { count: 13, data: {}, displayName: 'Outdoor furniture', status: '', value: 'Outdoor furniture' },
//           { count: 3, data: {}, displayName: 'Garden benches', status: '', value: 'Garden benches' },
//           { count: 8, data: {}, displayName: 'Cleaning', status: '', value: 'Cleaning' },
//           { count: 1, data: {}, displayName: 'Aircooler', status: '', value: 'Aircooler' },
//           { count: 2, data: {}, displayName: 'Rain barrel', status: '', value: 'Rain barrel' },
//           { count: 1, data: {}, displayName: 'Irrigation', status: '', value: 'Irrigation' },
//           { count: 6, data: {}, displayName: 'Faucet', status: '', value: 'Faucet' },
//           { count: 23, data: {}, displayName: 'Furniture', status: '', value: 'Furniture' },
//           { count: 6, data: {}, displayName: 'Wall decoration', status: '', value: 'Wall decoration' },
//           { count: 1, data: {}, displayName: 'Cables and housing', status: '', value: 'Cables and housing' },
//           { count: 2, data: {}, displayName: 'Washing machine', status: '', value: 'Washing machine' },
//           { count: 3, data: {}, displayName: 'Garden umbrella', status: '', value: 'Garden umbrella' },
//           { count: 2, data: {}, displayName: 'Parasol base', status: '', value: 'Parasol base' },
//           { count: 1, data: {}, displayName: 'Umbrella', status: '', value: 'Umbrella' },
//           { count: 1, data: {}, displayName: 'Floating parasol', status: '', value: 'Floating parasol' },
//           { count: 1, data: {}, displayName: 'Beach umbrella', status: '', value: 'Beach umbrella' },
//           { count: 7, data: {}, displayName: 'Save', status: '', value: 'Save' },
//           { count: 2, data: {}, displayName: 'paint ral-9010', status: '', value: 'paint ral-9010' },
//           { count: 6, data: {}, displayName: 'Curtains', status: '', value: 'Curtains' },
//           { count: 6, data: {}, displayName: 'Bedroom', status: '', value: 'Bedroom' },
//           { count: 7, data: {}, displayName: 'Outdoor lighting', status: '', value: 'Outdoor lighting' },
//           { count: 2, data: {}, displayName: 'Pavement', status: '', value: 'Pavement' },
//           { count: 1, data: {}, displayName: 'Artificial turf', status: '', value: 'Artificial turf' },
//           { count: 1, data: {}, displayName: 'Extractor hood', status: '', value: 'Extractor hood' },
//           { count: 1, data: {}, displayName: 'Roofing', status: '', value: 'Roofing' },
//           { count: 5, data: {}, displayName: 'Animals', status: '', value: 'Animals' },
//           { count: 4, data: {}, displayName: 'Autumn', status: '', value: 'Autumn' },
//           { count: 9, data: {}, displayName: 'Workplace', status: '', value: 'Workplace' },
//           { count: 8, data: {}, displayName: 'Wall paint', status: '', value: 'Wall paint' },
//           { count: 4, data: {}, displayName: 'Gardening', status: '', value: 'Gardening' },
//           { count: 3, data: {}, displayName: 'Outdoor life', status: '', value: 'Outdoor life' },
//           { count: 2, data: {}, displayName: 'Curtain rails', status: '', value: 'Curtain rails' },
//           { count: 2, data: {}, displayName: 'paint ral-9001', status: '', value: 'paint ral-9001' },
//           { count: 1, data: {}, displayName: 'Wood Rot Filler', status: '', value: 'Wood Rot Filler' },
//           { count: 1, data: {}, displayName: 'Bin', status: '', value: 'Bin' },
//           { count: 2, data: {}, displayName: 'Wall switch material', status: '', value: 'Wall switch material' },
//           { count: 2, data: {}, displayName: 'Wall lighting', status: '', value: 'Wall lighting' },
//           { count: 3, data: {}, displayName: 'Moving', status: '', value: 'Moving' },
//           { count: 1, data: {}, displayName: 'Blind', status: '', value: 'Blind' },
//           { count: 7, data: {}, displayName: 'Kids', status: '', value: 'Kids' },
//           { count: 5, data: {}, displayName: 'Children', status: '', value: 'Children' },
//           { count: 1, data: {}, displayName: 'Toys', status: '', value: 'Toys' },
//           { count: 1, data: {}, displayName: 'Summer holidays', status: '', value: 'Summer holidays' },
//           { count: 1, data: {}, displayName: 'Spring', status: '', value: 'Spring' },
//           { count: 1, data: {}, displayName: 'Ventilate', status: '', value: 'Ventilate' },
//           { count: 4, data: {}, displayName: 'Curtain', status: '', value: 'Curtain' },
//           { count: 2, data: {}, displayName: 'Door fitting', status: '', value: 'Door fitting' },
//           { count: 1, data: {}, displayName: 'Door handle', status: '', value: 'Door handle' },
//           { count: 9, data: {}, displayName: 'Greening', status: '', value: 'Greening' },
//           { count: 3, data: {}, displayName: 'Vegetable garden', status: '', value: 'Vegetable garden' },
//           { count: 3, data: {}, displayName: 'Garden chairs', status: '', value: 'Garden chairs' },
//           { count: 7, data: {}, displayName: 'Living room', status: '', value: 'Living room' },
//           { count: 3, data: {}, displayName: 'Holiday', status: '', value: 'Holiday' },
//           { count: 1, data: {}, displayName: 'Tools', status: '', value: 'Tools' },
//           { count: 2, data: {}, displayName: 'Floor tiles', status: '', value: 'Floor tiles' },
//           { count: 1, data: {}, displayName: 'Camping', status: '', value: 'Camping' },
//           { count: 1, data: {}, displayName: 'Plugs', status: '', value: 'Plugs' },
//           { count: 4, data: {}, displayName: 'Table', status: '', value: 'Table' },
//           { count: 1, data: {}, displayName: 'terrace', status: '', value: 'terrace' },
//           { count: 1, data: {}, displayName: 'Roof insulation', status: '', value: 'Roof insulation' },
//           { count: 1, data: {}, displayName: 'Leaf blowers', status: '', value: 'Leaf blowers' },
//           { count: 6, data: {}, displayName: 'Insulate', status: '', value: 'Insulate' },
//           { count: 2, data: {}, displayName: 'solar panels', status: '', value: 'solar panels' },
//           { count: 2, data: {}, displayName: 'Bathroom tiles', status: '', value: 'Bathroom tiles' },
//           { count: 1, data: {}, displayName: 'Firewood storage', status: '', value: 'Firewood storage' },
//           { count: 1, data: {}, displayName: 'Recessed spotlight', status: '', value: 'Recessed spotlight' },
//           { count: 2, data: {}, displayName: 'Paint roller', status: '', value: 'Paint roller' },
//           { count: 2, data: {}, displayName: 'Ventilation', status: '', value: 'Ventilation' },
//           { count: 2, data: {}, displayName: 'Stove', status: '', value: 'Stove' },
//           { count: 2, data: {}, displayName: 'Fireplace', status: '', value: 'Fireplace' },
//           { count: 1, data: {}, displayName: 'Car', status: '', value: 'Car' },
//           { count: 1, data: {}, displayName: 'Pond', status: '', value: 'Pond' },
//           { count: 3, data: {}, displayName: 'Scaffoldwood', status: '', value: 'Scaffoldwood' },
//           { count: 1, data: {}, displayName: 'Bathroom plants', status: '', value: 'Bathroom plants' },
//           { count: 1, data: {}, displayName: 'Air-purifying plants', status: '', value: 'Air-purifying plants' },
//           { count: 12, data: {}, displayName: 'DIY and garden calendar', status: '', value: 'DIY and garden calendar' },
//           { count: 1, data: {}, displayName: 'Wooden floor', status: '', value: 'Wooden floor' },
//           { count: 2, data: {}, displayName: 'Curtain rod', status: '', value: 'Curtain rod' },
//           { count: 3, data: {}, displayName: 'Grass', status: '', value: 'Grass' },
//           { count: 1, data: {}, displayName: 'Pleated blinds', status: '', value: 'Pleated blinds' },
//           { count: 2, data: {}, displayName: 'Fan', status: '', value: 'Fan' },
//           { count: 1, data: {}, displayName: 'Working from home', status: '', value: 'Working from home' },
//           { count: 1, data: {}, displayName: 'paint-textured', status: '', value: 'paint-textured' },
//           { count: 3, data: {}, displayName: 'Garden table', status: '', value: 'Garden table' },
//           { count: 1, data: {}, displayName: 'Moldings', status: '', value: 'Moldings' },
//           { count: 15, data: {}, displayName: 'Christmas', status: '', value: 'Christmas' },
//           { count: 2, data: {}, displayName: 'Cabinets', status: '', value: 'Cabinets' },
//           { count: 3, data: {}, displayName: 'Painting tips', status: '', value: 'Painting tips' },
//           { count: 1, data: {}, displayName: 'Rabbit hutch', status: '', value: 'Rabbit hutch' },
//           { count: 1, data: {}, displayName: 'Garden lover', status: '', value: 'Garden lover' },
//           { count: 1, data: {}, displayName: 'Smart home', status: '', value: 'Smart home' },
//           { count: 3, data: {}, displayName: 'Floor lamps', status: '', value: 'Floor lamps' },
//           { count: 1, data: {}, displayName: 'Kitchen combinations', status: '', value: 'Kitchen combinations' },
//           { count: 4, data: {}, displayName: 'Treehouse', status: '', value: 'Treehouse' },
//           { count: 3, data: {}, displayName: 'Storm', status: '', value: 'Storm' },
//           { count: 1, data: {}, displayName: 'Toilet fountains', status: '', value: 'Toilet fountains' },
//           { count: 1, data: {}, displayName: 'Socket', status: '', value: 'Socket' },
//           { count: 1, data: {}, displayName: 'Desk', status: '', value: 'Desk' },
//           { count: 1, data: {}, displayName: 'Foot lamp', status: '', value: 'Foot lamp' },
//           { count: 1, data: {}, displayName: 'Ceiling lamp', status: '', value: 'Ceiling lamp' },
//           { count: 2, data: {}, displayName: 'Masking tape', status: '', value: 'Masking tape' },
//           { count: 1, data: {}, displayName: 'Paint colors', status: '', value: 'Paint colors' },
//           { count: 2, data: {}, displayName: 'Students', status: '', value: 'Students' },
//           { count: 2, data: {}, displayName: 'Pets', status: '', value: 'Pets' },
//           { count: 4, data: {}, displayName: 'Christmas tree', status: '', value: 'Christmas tree' },
//           { count: 3, data: {}, displayName: 'Christmas decoration', status: '', value: 'Christmas decoration' },
//           { count: 2, data: {}, displayName: 'Gifts', status: '', value: 'Gifts' },
//           { count: 2, data: {}, displayName: 'December', status: '', value: 'December' },
//           { count: 2, data: {}, displayName: 'Rental house', status: '', value: 'Rental house' },
//           { count: 1, data: {}, displayName: "Father's day", status: '', value: "Father's day" },
//           { count: 1, data: {}, displayName: 'Easter', status: '', value: 'Easter' },
//           { count: 1, data: {}, displayName: 'Snow', status: '', value: 'Snow' },
//           { count: 1, data: {}, displayName: 'New Year', status: '', value: 'New Year' },
//           { count: 1, data: {}, displayName: "Mother's Day", status: '', value: "Mother's Day" },
//           { count: 1, data: {}, displayName: 'January', status: '', value: 'January' },
//           { count: 1, data: {}, displayName: 'Garden tiles', status: '', value: 'Garden tiles' },
//           { count: 2, data: {}, displayName: 'Gardening tips', status: '', value: 'Gardening tips' }
//         ],
//         type: 'multiple',
//         data: {},
//         hidden: false
//       },
//       {
//         displayName: 'keywords',
//         name: 'keywords',
//         options: [
//           { count: 9, data: {}, displayName: 'schutting', status: '', value: 'schutting' },
//           { count: 8, data: {}, displayName: 'tuinhout', status: '', value: 'tuinhout' },
//           { count: 6, data: {}, displayName: 'tuinmeubels', status: '', value: 'tuinmeubels' },
//           { count: 6, data: {}, displayName: 'tuinmeubelen', status: '', value: 'tuinmeubelen' },
//           { count: 6, data: {}, displayName: 'tuinbank', status: '', value: 'tuinbank' },
//           { count: 6, data: {}, displayName: 'overkapping', status: '', value: 'overkapping' },
//           { count: 3, data: {}, displayName: 'vlonder', status: '', value: 'vlonder' },
//           { count: 2, data: {}, displayName: 'vlonderplank', status: '', value: 'vlonderplank' },
//           { count: 2, data: {}, displayName: 'veranda', status: '', value: 'veranda' },
//           { count: 1, data: {}, displayName: 'zachthout', status: '', value: 'zachthout' },
//           { count: 1, data: {}, displayName: 'wpc composiet', status: '', value: 'wpc composiet' },
//           { count: 1, data: {}, displayName: 'trellis', status: '', value: 'trellis' },
//           { count: 1, data: {}, displayName: 'terrastegel', status: '', value: 'terrastegel' },
//           { count: 1, data: {}, displayName: 'hardhout', status: '', value: 'hardhout' },
//           { count: 1, data: {}, displayName: 'eiken', status: '', value: 'eiken' },
//           { count: 1, data: {}, displayName: 'duurzaam hout', status: '', value: 'duurzaam hout' },
//           { count: 1, data: {}, displayName: 'douglashout', status: '', value: 'douglashout' },
//           { count: 5, data: {}, displayName: 'raam', status: '', value: 'raam' },
//           { count: 5, data: {}, displayName: 'horren', status: '', value: 'horren' },
//           { count: 4, data: {}, displayName: 'hor', status: '', value: 'hor' },
//           { count: 2, data: {}, displayName: 'inzethor', status: '', value: 'inzethor' },
//           { count: 1, data: {}, displayName: 'inzethorren', status: '', value: 'inzethorren' },
//           { count: 1, data: {}, displayName: 'insectenhor', status: '', value: 'insectenhor' },
//           { count: 4, data: {}, displayName: 'kraan', status: '', value: 'kraan' },
//           { count: 4, data: {}, displayName: 'doucheset', status: '', value: 'doucheset' },
//           { count: 1, data: {}, displayName: 'stortdouche', status: '', value: 'stortdouche' },
//           { count: 1, data: {}, displayName: 'set', status: '', value: 'set' },
//           { count: 1, data: {}, displayName: 'regendouche', status: '', value: 'regendouche' },
//           { count: 1, data: {}, displayName: 'inbouw douche', status: '', value: 'inbouw douche' },
//           { count: 1, data: {}, displayName: 'douchestang', status: '', value: 'douchestang' },
//           { count: 1, data: {}, displayName: 'doucheset zwart', status: '', value: 'doucheset zwart' },
//           { count: 1, data: {}, displayName: 'doucheset zilver', status: '', value: 'doucheset zilver' },
//           { count: 1, data: {}, displayName: 'doucheset goud', status: '', value: 'doucheset goud' },
//           { count: 7, data: {}, displayName: 'badkamer', status: '', value: 'badkamer' },
//           { count: 4, data: {}, displayName: 'wastafel', status: '', value: 'wastafel' },
//           { count: 4, data: {}, displayName: 'badkamermeubel', status: '', value: 'badkamermeubel' },
//           { count: 1, data: {}, displayName: 'waskom', status: '', value: 'waskom' },
//           { count: 1, data: {}, displayName: 'spiegelkast', status: '', value: 'spiegelkast' },
//           { count: 1, data: {}, displayName: 'onderkast', status: '', value: 'onderkast' },
//           { count: 1, data: {}, displayName: 'kolomkast', status: '', value: 'kolomkast' },
//           { count: 1, data: {}, displayName: 'badkamer meubel', status: '', value: 'badkamer meubel' },
//           { count: 23, data: {}, displayName: 'tuin', status: '', value: 'tuin' },
//           { count: 19, data: {}, displayName: 'hout', status: '', value: 'hout' },
//           { count: 3, data: {}, displayName: 'sloten', status: '', value: 'sloten' },
//           { count: 3, data: {}, displayName: 'schuttingplank', status: '', value: 'schuttingplank' },
//           { count: 2, data: {}, displayName: 'schuttingen', status: '', value: 'schuttingen' },
//           { count: 2, data: {}, displayName: 'planken', status: '', value: 'planken' },
//           { count: 2, data: {}, displayName: 'plank', status: '', value: 'plank' },
//           { count: 1, data: {}, displayName: 'tuinscherm', status: '', value: 'tuinscherm' },
//           { count: 1, data: {}, displayName: 'tuinpalen', status: '', value: 'tuinpalen' },
//           { count: 1, data: {}, displayName: 'rabatdelen', status: '', value: 'rabatdelen' },
//           { count: 1, data: {}, displayName: 'palen', status: '', value: 'palen' },
//           { count: 1, data: {}, displayName: 'grendel', status: '', value: 'grendel' },
//           { count: 1, data: {}, displayName: 'beslag', status: '', value: 'beslag' },
//           { count: 8, data: {}, displayName: 'deur', status: '', value: 'deur' },
//           { count: 3, data: {}, displayName: 'hordeur', status: '', value: 'hordeur' },
//           { count: 1, data: {}, displayName: 'voorzethor', status: '', value: 'voorzethor' },
//           { count: 1, data: {}, displayName: 'vlieggordijn', status: '', value: 'vlieggordijn' },
//           { count: 1, data: {}, displayName: 'vliegengordijn', status: '', value: 'vliegengordijn' },
//           { count: 1, data: {}, displayName: 'vaste hordeur', status: '', value: 'vaste hordeur' },
//           { count: 1, data: {}, displayName: 'rolhordeur', status: '', value: 'rolhordeur' },
//           { count: 1, data: {}, displayName: 'rolhor', status: '', value: 'rolhor' },
//           { count: 1, data: {}, displayName: 'raamhorren', status: '', value: 'raamhorren' },
//           { count: 1, data: {}, displayName: 'raamhor', status: '', value: 'raamhor' },
//           { count: 1, data: {}, displayName: 'plisséhordeur', status: '', value: 'plisséhordeur' },
//           { count: 1, data: {}, displayName: 'horraam', status: '', value: 'horraam' },
//           { count: 1, data: {}, displayName: 'horgordijn', status: '', value: 'horgordijn' },
//           { count: 12, data: {}, displayName: 'tegels', status: '', value: 'tegels' },
//           { count: 3, data: {}, displayName: 'wandtegels', status: '', value: 'wandtegels' },
//           { count: 1, data: {}, displayName: 'wandtegels leggen', status: '', value: 'wandtegels leggen' },
//           { count: 1, data: {}, displayName: 'wand tegelen', status: '', value: 'wand tegelen' },
//           { count: 1, data: {}, displayName: 'muur tegelen', status: '', value: 'muur tegelen' },
//           { count: 1, data: {}, displayName: 'muur betegelen', status: '', value: 'muur betegelen' },
//           { count: 1, data: {}, displayName: 'betegelen', status: '', value: 'betegelen' },
//           { count: 1, data: {}, displayName: 'badkamer tegels', status: '', value: 'badkamer tegels' },
//           { count: 10, data: {}, displayName: 'planten', status: '', value: 'planten' },
//           { count: 4, data: {}, displayName: 'tuinplant', status: '', value: 'tuinplant' },
//           { count: 4, data: {}, displayName: 'plant', status: '', value: 'plant' },
//           { count: 3, data: {}, displayName: 'potgrond', status: '', value: 'potgrond' },
//           { count: 2, data: {}, displayName: 'viool', status: '', value: 'viool' },
//           { count: 2, data: {}, displayName: 'olijfboom', status: '', value: 'olijfboom' },
//           { count: 2, data: {}, displayName: 'margriet', status: '', value: 'margriet' },
//           { count: 2, data: {}, displayName: 'hortensia', status: '', value: 'hortensia' },
//           { count: 2, data: {}, displayName: 'bloemen', status: '', value: 'bloemen' },
//           { count: 1, data: {}, displayName: 'verbena', status: '', value: 'verbena' },
//           { count: 1, data: {}, displayName: 'spaanse margriet', status: '', value: 'spaanse margriet' },
//           { count: 1, data: {}, displayName: 'petunia', status: '', value: 'petunia' },
//           { count: 1, data: {}, displayName: 'palm', status: '', value: 'palm' },
//           { count: 1, data: {}, displayName: 'lavendel', status: '', value: 'lavendel' },
//           { count: 1, data: {}, displayName: 'heester', status: '', value: 'heester' },
//           { count: 1, data: {}, displayName: 'geranium', status: '', value: 'geranium' },
//           { count: 9, data: {}, displayName: 'raamdecoratie', status: '', value: 'raamdecoratie' },
//           { count: 9, data: {}, displayName: 'gordijnen', status: '', value: 'gordijnen' },
//           { count: 7, data: {}, displayName: 'gordijn', status: '', value: 'gordijn' },
//           { count: 3, data: {}, displayName: 'rolgordijnen', status: '', value: 'rolgordijnen' },
//           { count: 3, data: {}, displayName: 'rolgordijn', status: '', value: 'rolgordijn' },
//           { count: 2, data: {}, displayName: 'vouwgordijnen', status: '', value: 'vouwgordijnen' },
//           { count: 2, data: {}, displayName: 'vouwgordijn', status: '', value: 'vouwgordijn' },
//           { count: 2, data: {}, displayName: 'plissegordijn', status: '', value: 'plissegordijn' },
//           { count: 2, data: {}, displayName: 'jaloezien', status: '', value: 'jaloezien' },
//           { count: 2, data: {}, displayName: 'jaloezie', status: '', value: 'jaloezie' },
//           { count: 1, data: {}, displayName: 'verduisteringsgordijn', status: '', value: 'verduisteringsgordijn' },
//           { count: 1, data: {}, displayName: 'shutters', status: '', value: 'shutters' },
//           { count: 1, data: {}, displayName: 'roljaloezie', status: '', value: 'roljaloezie' },
//           { count: 1, data: {}, displayName: 'raamdeco', status: '', value: 'raamdeco' },
//           { count: 1, data: {}, displayName: 'plisségordijn', status: '', value: 'plisségordijn' },
//           { count: 1, data: {}, displayName: 'plisse', status: '', value: 'plisse' },
//           { count: 1, data: {}, displayName: 'lamellen', status: '', value: 'lamellen' },
//           {
//             count: 1,
//             data: {},
//             displayName: 'isolerende raamdecoratie',
//             status: '',
//             value: 'isolerende raamdecoratie'
//           },
//           { count: 1, data: {}, displayName: 'dakraamdecoratie', status: '', value: 'dakraamdecoratie' },
//           { count: 10, data: {}, displayName: 'verlichting', status: '', value: 'verlichting' },
//           { count: 5, data: {}, displayName: 'elektra', status: '', value: 'elektra' },
//           { count: 4, data: {}, displayName: 'stopcontact', status: '', value: 'stopcontact' },
//           { count: 2, data: {}, displayName: 'schakelmateriaal', status: '', value: 'schakelmateriaal' },
//           { count: 2, data: {}, displayName: 'lichtschakelaar', status: '', value: 'lichtschakelaar' },
//           { count: 2, data: {}, displayName: 'dimmer', status: '', value: 'dimmer' },
//           { count: 1, data: {}, displayName: 'schakelaar', status: '', value: 'schakelaar' },
//           { count: 1, data: {}, displayName: 'electra', status: '', value: 'electra' },
//           { count: 4, data: {}, displayName: 'dak', status: '', value: 'dak' },
//           { count: 2, data: {}, displayName: 'dakgoot', status: '', value: 'dakgoot' },
//           { count: 1, data: {}, displayName: 'dakgootreparatie', status: '', value: 'dakgootreparatie' },
//           { count: 1, data: {}, displayName: 'dakgoot repareren', status: '', value: 'dakgoot repareren' },
//           { count: 1, data: {}, displayName: 'dakgoot reparatie', status: '', value: 'dakgoot reparatie' },
//           { count: 2, data: {}, displayName: 'trap', status: '', value: 'trap' },
//           { count: 1, data: {}, displayName: 'traprenovatie', status: '', value: 'traprenovatie' },
//           { count: 1, data: {}, displayName: 'renoveren', status: '', value: 'renoveren' },
//           { count: 1, data: {}, displayName: 'overzettreden', status: '', value: 'overzettreden' },
//           { count: 1, data: {}, displayName: 'cando traprenovatie', status: '', value: 'cando traprenovatie' },
//           { count: 1, data: {}, displayName: 'bekleden', status: '', value: 'bekleden' },
//           { count: 11, data: {}, displayName: 'zonwering', status: '', value: 'zonwering' },
//           { count: 9, data: {}, displayName: 'balkon', status: '', value: 'balkon' },
//           { count: 7, data: {}, displayName: 'pergola', status: '', value: 'pergola' },
//           { count: 6, data: {}, displayName: 'zonnescherm', status: '', value: 'zonnescherm' },
//           { count: 6, data: {}, displayName: 'schaduwdoek', status: '', value: 'schaduwdoek' },
//           { count: 5, data: {}, displayName: 'terras', status: '', value: 'terras' },
//           { count: 4, data: {}, displayName: 'parasol', status: '', value: 'parasol' },
//           { count: 3, data: {}, displayName: 'schaduw', status: '', value: 'schaduw' },
//           { count: 2, data: {}, displayName: 'terrasoverkapping', status: '', value: 'terrasoverkapping' },
//           { count: 2, data: {}, displayName: 'rolluik', status: '', value: 'rolluik' },
//           { count: 1, data: {}, displayName: 'zonlicht', status: '', value: 'zonlicht' },
//           { count: 1, data: {}, displayName: 'zon', status: '', value: 'zon' },
//           { count: 1, data: {}, displayName: 'zomer', status: '', value: 'zomer' },
//           { count: 2, data: {}, displayName: 'kitten', status: '', value: 'kitten' },
//           {
//             count: 1,
//             data: {},
//             displayName: 'siliconenkit verwijderen',
//             status: '',
//             value: 'siliconenkit verwijderen'
//           },
//           { count: 1, data: {}, displayName: 'kitverwijderaar', status: '', value: 'kitverwijderaar' },
//           { count: 1, data: {}, displayName: 'kit verwijderen', status: '', value: 'kit verwijderen' },
//           { count: 1, data: {}, displayName: 'kit verwijderaar', status: '', value: 'kit verwijderaar' },
//           { count: 1, data: {}, displayName: 'kit', status: '', value: 'kit' },
//           { count: 6, data: {}, displayName: 'behang', status: '', value: 'behang' },
//           { count: 5, data: {}, displayName: 'behangen', status: '', value: 'behangen' },
//           { count: 1, data: {}, displayName: 'prikroller', status: '', value: 'prikroller' },
//           { count: 1, data: {}, displayName: 'behangperforator', status: '', value: 'behangperforator' },
//           { count: 1, data: {}, displayName: 'behangafstomer', status: '', value: 'behangafstomer' },
//           { count: 1, data: {}, displayName: 'behang verwijderen', status: '', value: 'behang verwijderen' },
//           { count: 1, data: {}, displayName: 'afweekmiddel', status: '', value: 'afweekmiddel' },
//           { count: 4, data: {}, displayName: 'vloerlamp', status: '', value: 'vloerlamp' },
//           { count: 4, data: {}, displayName: 'plafondlamp', status: '', value: 'plafondlamp' },
//           { count: 3, data: {}, displayName: 'hanglamp', status: '', value: 'hanglamp' },
//           { count: 2, data: {}, displayName: 'wandlamp', status: '', value: 'wandlamp' },
//           { count: 2, data: {}, displayName: 'tafellamp', status: '', value: 'tafellamp' },
//           { count: 2, data: {}, displayName: 'ledlamp', status: '', value: 'ledlamp' },
//           { count: 2, data: {}, displayName: 'led lamp', status: '', value: 'led lamp' },
//           { count: 1, data: {}, displayName: 'ledlampen', status: '', value: 'ledlampen' },
//           { count: 1, data: {}, displayName: 'led lampen', status: '', value: 'led lampen' },
//           { count: 1, data: {}, displayName: 'led', status: '', value: 'led' },
//           { count: 5, data: {}, displayName: 'steigerhout', status: '', value: 'steigerhout' },
//           { count: 2, data: {}, displayName: 'mdf', status: '', value: 'mdf' },
//           { count: 1, data: {}, displayName: 'underlayment', status: '', value: 'underlayment' },
//           { count: 1, data: {}, displayName: 'spaanplaat', status: '', value: 'spaanplaat' },
//           { count: 1, data: {}, displayName: 'osb', status: '', value: 'osb' },
//           { count: 1, data: {}, displayName: 'multiplex', status: '', value: 'multiplex' },
//           { count: 1, data: {}, displayName: 'meubelpaneel', status: '', value: 'meubelpaneel' },
//           { count: 1, data: {}, displayName: 'hardboard', status: '', value: 'hardboard' },
//           { count: 24, data: {}, displayName: 'verf', status: '', value: 'verf' },
//           { count: 18, data: {}, displayName: 'verven', status: '', value: 'verven' },
//           { count: 14, data: {}, displayName: 'schilderen', status: '', value: 'schilderen' },
//           { count: 13, data: {}, displayName: 'muurverf', status: '', value: 'muurverf' },
//           { count: 5, data: {}, displayName: 'beits', status: '', value: 'beits' },
//           { count: 4, data: {}, displayName: 'verfroller', status: '', value: 'verfroller' },
//           { count: 4, data: {}, displayName: 'beitsen', status: '', value: 'beitsen' },
//           { count: 3, data: {}, displayName: 'lak', status: '', value: 'lak' },
//           { count: 3, data: {}, displayName: 'kwasten', status: '', value: 'kwasten' },
//           { count: 3, data: {}, displayName: 'kwast', status: '', value: 'kwast' },
//           { count: 2, data: {}, displayName: 'structuurverf', status: '', value: 'structuurverf' },
//           { count: 2, data: {}, displayName: 'krijtverf', status: '', value: 'krijtverf' },
//           { count: 2, data: {}, displayName: 'grondverf', status: '', value: 'grondverf' },
//           { count: 1, data: {}, displayName: 'waterbasis', status: '', value: 'waterbasis' },
//           { count: 1, data: {}, displayName: 'roller', status: '', value: 'roller' },
//           { count: 1, data: {}, displayName: 'primer', status: '', value: 'primer' },
//           { count: 1, data: {}, displayName: 'metaalverf', status: '', value: 'metaalverf' },
//           { count: 1, data: {}, displayName: 'metaal verf', status: '', value: 'metaal verf' },
//           { count: 1, data: {}, displayName: 'lakken', status: '', value: 'lakken' },
//           { count: 1, data: {}, displayName: 'grondlaag', status: '', value: 'grondlaag' },
//           { count: 1, data: {}, displayName: 'wc inbouwen', status: '', value: 'wc inbouwen' },
//           { count: 1, data: {}, displayName: 'wc inbouw', status: '', value: 'wc inbouw' },
//           {
//             count: 1,
//             data: {},
//             displayName: 'toilet met inbouwreservoir',
//             status: '',
//             value: 'toilet met inbouwreservoir'
//           },
//           { count: 1, data: {}, displayName: 'toilet inbouwen', status: '', value: 'toilet inbouwen' },
//           { count: 1, data: {}, displayName: 'inbouwreservoir', status: '', value: 'inbouwreservoir' },
//           { count: 6, data: {}, displayName: 'vloeren', status: '', value: 'vloeren' },
//           { count: 4, data: {}, displayName: 'pvc', status: '', value: 'pvc' },
//           { count: 1, data: {}, displayName: 'visgraat vloer', status: '', value: 'visgraat vloer' },
//           { count: 1, data: {}, displayName: 'kliksysteem', status: '', value: 'kliksysteem' },
//           { count: 1, data: {}, displayName: 'hout pvc', status: '', value: 'hout pvc' },
//           { count: 1, data: {}, displayName: 'beton pvc', status: '', value: 'beton pvc' },
//           { count: 2, data: {}, displayName: 'afkitten', status: '', value: 'afkitten' },
//           { count: 1, data: {}, displayName: 'voegen kitten', status: '', value: 'voegen kitten' },
//           { count: 1, data: {}, displayName: 'voegen', status: '', value: 'voegen' },
//           { count: 1, data: {}, displayName: 'siliconenkit sanitair', status: '', value: 'siliconenkit sanitair' },
//           { count: 1, data: {}, displayName: 'siliconenkit', status: '', value: 'siliconenkit' },
//           { count: 5, data: {}, displayName: 'zwembad', status: '', value: 'zwembad' },
//           { count: 2, data: {}, displayName: 'opzetzwembad', status: '', value: 'opzetzwembad' },
//           { count: 2, data: {}, displayName: 'opblaaszwembad', status: '', value: 'opblaaszwembad' },
//           { count: 2, data: {}, displayName: 'hottub', status: '', value: 'hottub' },
//           { count: 2, data: {}, displayName: 'filterpomp', status: '', value: 'filterpomp' },
//           { count: 2, data: {}, displayName: 'chloor', status: '', value: 'chloor' },
//           { count: 1, data: {}, displayName: 'teststrip', status: '', value: 'teststrip' },
//           { count: 1, data: {}, displayName: 'oppervlaktenet', status: '', value: 'oppervlaktenet' },
//           { count: 1, data: {}, displayName: 'onderhoud zwembad', status: '', value: 'onderhoud zwembad' },
//           { count: 1, data: {}, displayName: 'filterbollen', status: '', value: 'filterbollen' },
//           { count: 1, data: {}, displayName: 'filterbol', status: '', value: 'filterbol' },
//           { count: 1, data: {}, displayName: 'filter', status: '', value: 'filter' },
//           { count: 1, data: {}, displayName: 'chloortabletten', status: '', value: 'chloortabletten' },
//           { count: 1, data: {}, displayName: 'bodemstofzuiger', status: '', value: 'bodemstofzuiger' },
//           { count: 6, data: {}, displayName: 'airco', status: '', value: 'airco' },
//           { count: 4, data: {}, displayName: 'airconditioning', status: '', value: 'airconditioning' },
//           { count: 1, data: {}, displayName: 'airco zonder slang', status: '', value: 'airco zonder slang' },
//           { count: 5, data: {}, displayName: 'raamfolie', status: '', value: 'raamfolie' },
//           { count: 3, data: {}, displayName: 'folie', status: '', value: 'folie' },
//           { count: 2, data: {}, displayName: 'isolerende raamfolie', status: '', value: 'isolerende raamfolie' },
//           { count: 1, data: {}, displayName: 'raam folie', status: '', value: 'raam folie' },
//           { count: 1, data: {}, displayName: 'plakfolie', status: '', value: 'plakfolie' },
//           { count: 2, data: {}, displayName: 'jacuzzi', status: '', value: 'jacuzzi' },
//           { count: 1, data: {}, displayName: 'layz spa', status: '', value: 'layz spa' },
//           { count: 1, data: {}, displayName: 'lay z spa', status: '', value: 'lay z spa' },
//           { count: 1, data: {}, displayName: 'bubbelbad', status: '', value: 'bubbelbad' },
//           { count: 1, data: {}, displayName: 'voegmiddel tegels', status: '', value: 'voegmiddel tegels' },
//           { count: 1, data: {}, displayName: 'voegmiddel', status: '', value: 'voegmiddel' },
//           { count: 1, data: {}, displayName: 'voeg', status: '', value: 'voeg' },
//           { count: 1, data: {}, displayName: 'tegels voegen', status: '', value: 'tegels voegen' },
//           { count: 1, data: {}, displayName: 'tegel voegen', status: '', value: 'tegel voegen' },
//           { count: 12, data: {}, displayName: 'vloer', status: '', value: 'vloer' },
//           { count: 7, data: {}, displayName: 'laminaat', status: '', value: 'laminaat' },
//           { count: 3, data: {}, displayName: 'ondervloer', status: '', value: 'ondervloer' },
//           { count: 3, data: {}, displayName: 'laminaat leggen', status: '', value: 'laminaat leggen' },
//           { count: 2, data: {}, displayName: 'plinten', status: '', value: 'plinten' },
//           { count: 1, data: {}, displayName: 'laminaatsnijder', status: '', value: 'laminaatsnijder' },
//           { count: 1, data: {}, displayName: 'hoe leg ik laminaat', status: '', value: 'hoe leg ik laminaat' },
//           { count: 9, data: {}, displayName: 'isoleren', status: '', value: 'isoleren' },
//           { count: 9, data: {}, displayName: 'isolatiemateriaal', status: '', value: 'isolatiemateriaal' },
//           { count: 2, data: {}, displayName: 'verduurzamen', status: '', value: 'verduurzamen' },
//           { count: 2, data: {}, displayName: 'buisisolatie', status: '', value: 'buisisolatie' },
//           { count: 1, data: {}, displayName: 'verwarmingsbuizen', status: '', value: 'verwarmingsbuizen' },
//           { count: 1, data: {}, displayName: 'duurzaamheid', status: '', value: 'duurzaamheid' },
//           {
//             count: 1,
//             data: {},
//             displayName: 'gipswand stucklaar maken',
//             status: '',
//             value: 'gipswand stucklaar maken'
//           },
//           { count: 1, data: {}, displayName: 'gipsplaten afwerken', status: '', value: 'gipsplaten afwerken' },
//           { count: 11, data: {}, displayName: 'muur', status: '', value: 'muur' },
//           { count: 3, data: {}, displayName: 'muren', status: '', value: 'muren' },
//           { count: 1, data: {}, displayName: 'stucen', status: '', value: 'stucen' },
//           { count: 1, data: {}, displayName: 'muur glad zonder stucen', status: '', value: 'muur glad zonder stucen' },
//           { count: 1, data: {}, displayName: 'muur glad maken', status: '', value: 'muur glad maken' },
//           { count: 1, data: {}, displayName: 'muren glad maken', status: '', value: 'muren glad maken' },
//           { count: 1, data: {}, displayName: 'alabastine', status: '', value: 'alabastine' },
//           { count: 1, data: {}, displayName: 'jaloezieën', status: '', value: 'jaloezieën' },
//           { count: 2, data: {}, displayName: 'hout beitsen', status: '', value: 'hout beitsen' },
//           { count: 1, data: {}, displayName: 'tuinmeubel verven', status: '', value: 'tuinmeubel verven' },
//           { count: 1, data: {}, displayName: 'buiten verven', status: '', value: 'buiten verven' },
//           { count: 1, data: {}, displayName: 'buiten lakken', status: '', value: 'buiten lakken' },
//           { count: 1, data: {}, displayName: 'beitsverf', status: '', value: 'beitsverf' },
//           { count: 4, data: {}, displayName: 'lamp', status: '', value: 'lamp' },
//           { count: 3, data: {}, displayName: 'lampen', status: '', value: 'lampen' },
//           { count: 2, data: {}, displayName: 'binnenverlichting', status: '', value: 'binnenverlichting' },
//           { count: 1, data: {}, displayName: 'lamp ophangen', status: '', value: 'lamp ophangen' },
//           { count: 3, data: {}, displayName: 'slot', status: '', value: 'slot' },
//           { count: 2, data: {}, displayName: 'cilinderslot', status: '', value: 'cilinderslot' },
//           { count: 1, data: {}, displayName: 'deursloten', status: '', value: 'deursloten' },
//           { count: 1, data: {}, displayName: 'deurslot vervangen', status: '', value: 'deurslot vervangen' },
//           { count: 1, data: {}, displayName: 'deurslot', status: '', value: 'deurslot' },
//           { count: 1, data: {}, displayName: 'cylinderslot', status: '', value: 'cylinderslot' },
//           { count: 5, data: {}, displayName: 'keuken', status: '', value: 'keuken' },
//           { count: 2, data: {}, displayName: 'wc', status: '', value: 'wc' },
//           { count: 2, data: {}, displayName: 'toilet', status: '', value: 'toilet' },
//           { count: 1, data: {}, displayName: 'kranen', status: '', value: 'kranen' },
//           { count: 2, data: {}, displayName: 'kozijnen', status: '', value: 'kozijnen' },
//           { count: 1, data: {}, displayName: 'raamkozijn', status: '', value: 'raamkozijn' },
//           { count: 1, data: {}, displayName: 'kozijnen schilderen', status: '', value: 'kozijnen schilderen' },
//           { count: 1, data: {}, displayName: 'kozijn verf', status: '', value: 'kozijn verf' },
//           {
//             count: 1,
//             data: {},
//             displayName: 'buitenkozijnen schilderen',
//             status: '',
//             value: 'buitenkozijnen schilderen'
//           },
//           { count: 1, data: {}, displayName: 'buitenkozijnen', status: '', value: 'buitenkozijnen' },
//           { count: 1, data: {}, displayName: 'buitenkozijn', status: '', value: 'buitenkozijn' },
//           { count: 6, data: {}, displayName: 'isolatie', status: '', value: 'isolatie' },
//           { count: 4, data: {}, displayName: 'tochtstrip', status: '', value: 'tochtstrip' },
//           { count: 3, data: {}, displayName: 'dak isoleren', status: '', value: 'dak isoleren' },
//           { count: 2, data: {}, displayName: 'muur isoleren', status: '', value: 'muur isoleren' },
//           { count: 1, data: {}, displayName: 'steenwol', status: '', value: 'steenwol' },
//           { count: 1, data: {}, displayName: 'schapenwol', status: '', value: 'schapenwol' },
//           { count: 1, data: {}, displayName: 'platen', status: '', value: 'platen' },
//           { count: 1, data: {}, displayName: 'isolatieschui,', status: '', value: 'isolatieschui,' },
//           { count: 1, data: {}, displayName: 'isolatieplaten', status: '', value: 'isolatieplaten' },
//           { count: 1, data: {}, displayName: 'isolatieplaat', status: '', value: 'isolatieplaat' },
//           { count: 1, data: {}, displayName: 'isolatiefolie', status: '', value: 'isolatiefolie' },
//           { count: 1, data: {}, displayName: 'eps', status: '', value: 'eps' },
//           { count: 2, data: {}, displayName: 'buitenmuur', status: '', value: 'buitenmuur' },
//           {
//             count: 1,
//             data: {},
//             displayName: 'schilderen van buitenmuren',
//             status: '',
//             value: 'schilderen van buitenmuren'
//           },
//           { count: 1, data: {}, displayName: 'muur buiten verven', status: '', value: 'muur buiten verven' },
//           { count: 1, data: {}, displayName: 'buitenmuur verven', status: '', value: 'buitenmuur verven' },
//           { count: 1, data: {}, displayName: 'buitenmuur schilderen', status: '', value: 'buitenmuur schilderen' },
//           { count: 2, data: {}, displayName: 'lijm', status: '', value: 'lijm' },
//           { count: 1, data: {}, displayName: 'plintclips', status: '', value: 'plintclips' },
//           { count: 1, data: {}, displayName: 'plakplinten', status: '', value: 'plakplinten' },
//           { count: 1, data: {}, displayName: 'montagekit', status: '', value: 'montagekit' },
//           { count: 1, data: {}, displayName: 'lage plinten', status: '', value: 'lage plinten' },
//           { count: 1, data: {}, displayName: 'hoge plinten', status: '', value: 'hoge plinten' },
//           { count: 1, data: {}, displayName: 'wrappen', status: '', value: 'wrappen' },
//           { count: 1, data: {}, displayName: 'wrapfolie', status: '', value: 'wrapfolie' },
//           { count: 1, data: {}, displayName: 'keukenwrap', status: '', value: 'keukenwrap' },
//           { count: 1, data: {}, displayName: 'keuken make-over', status: '', value: 'keuken make-over' },
//           { count: 1, data: {}, displayName: 'decoratiefolie', status: '', value: 'decoratiefolie' },
//           { count: 2, data: {}, displayName: 'tuinkast', status: '', value: 'tuinkast' },
//           { count: 1, data: {}, displayName: 'tuinopslag', status: '', value: 'tuinopslag' },
//           { count: 1, data: {}, displayName: 'tuinkasten', status: '', value: 'tuinkasten' },
//           { count: 1, data: {}, displayName: 'opslag tuin', status: '', value: 'opslag tuin' },
//           { count: 1, data: {}, displayName: 'opslag balkon', status: '', value: 'opslag balkon' },
//           { count: 1, data: {}, displayName: 'kasten tuin', status: '', value: 'kasten tuin' },
//           { count: 1, data: {}, displayName: 'balkonopslag', status: '', value: 'balkonopslag' },
//           { count: 1, data: {}, displayName: 'balkonkasten', status: '', value: 'balkonkasten' },
//           { count: 1, data: {}, displayName: 'balkonkast', status: '', value: 'balkonkast' },
//           { count: 2, data: {}, displayName: 'boormachine', status: '', value: 'boormachine' },
//           { count: 1, data: {}, displayName: 'slagmoeraanzetter', status: '', value: 'slagmoeraanzetter' },
//           { count: 1, data: {}, displayName: 'kolomboormachine', status: '', value: 'kolomboormachine' },
//           { count: 1, data: {}, displayName: 'klopboormachine', status: '', value: 'klopboormachine' },
//           { count: 1, data: {}, displayName: 'klopboor', status: '', value: 'klopboor' },
//           { count: 1, data: {}, displayName: 'breekhamer', status: '', value: 'breekhamer' },
//           { count: 1, data: {}, displayName: 'boren', status: '', value: 'boren' },
//           { count: 1, data: {}, displayName: 'boormachines', status: '', value: 'boormachines' },
//           { count: 1, data: {}, displayName: 'boorhamer', status: '', value: 'boorhamer' },
//           { count: 1, data: {}, displayName: 'accuboormachine', status: '', value: 'accuboormachine' },
//           { count: 1, data: {}, displayName: 'ruit', status: '', value: 'ruit' },
//           { count: 1, data: {}, displayName: 'glas', status: '', value: 'glas' },
//           { count: 1, data: {}, displayName: 'muurvuller', status: '', value: 'muurvuller' },
//           { count: 1, data: {}, displayName: 'muurreparatie', status: '', value: 'muurreparatie' },
//           { count: 1, data: {}, displayName: 'grote gatenvuller', status: '', value: 'grote gatenvuller' },
//           { count: 1, data: {}, displayName: 'gatenvuller', status: '', value: 'gatenvuller' },
//           { count: 1, data: {}, displayName: 'gaten vullen in de muur', status: '', value: 'gaten vullen in de muur' },
//           { count: 1, data: {}, displayName: 'gaten vullen', status: '', value: 'gaten vullen' },
//           {
//             count: 1,
//             data: {},
//             displayName: 'alabastine grote gatenvuller',
//             status: '',
//             value: 'alabastine grote gatenvuller'
//           },
//           { count: 1, data: {}, displayName: 'ladder', status: '', value: 'ladder' },
//           { count: 1, data: {}, displayName: 'gootdrain', status: '', value: 'gootdrain' },
//           { count: 1, data: {}, displayName: 'autowas', status: '', value: 'autowas' },
//           { count: 2, data: {}, displayName: 'spiegel', status: '', value: 'spiegel' },
//           { count: 1, data: {}, displayName: 'spiegellamp', status: '', value: 'spiegellamp' },
//           { count: 1, data: {}, displayName: 'ip-waarde', status: '', value: 'ip-waarde' },
//           { count: 1, data: {}, displayName: 'ip waarde', status: '', value: 'ip waarde' },
//           { count: 1, data: {}, displayName: 'inbouwspots', status: '', value: 'inbouwspots' },
//           { count: 1, data: {}, displayName: 'inbouwspot', status: '', value: 'inbouwspot' },
//           { count: 1, data: {}, displayName: 'badkamerverlichting', status: '', value: 'badkamerverlichting' },
//           { count: 1, data: {}, displayName: 'badkamer verlichting', status: '', value: 'badkamer verlichting' },
//           { count: 7, data: {}, displayName: 'tuintafel', status: '', value: 'tuintafel' },
//           { count: 6, data: {}, displayName: 'tuinstoel', status: '', value: 'tuinstoel' },
//           { count: 6, data: {}, displayName: 'tuinset', status: '', value: 'tuinset' },
//           { count: 6, data: {}, displayName: 'tuinmeubel', status: '', value: 'tuinmeubel' },
//           { count: 6, data: {}, displayName: 'loungeset', status: '', value: 'loungeset' },
//           { count: 2, data: {}, displayName: 'loungebank', status: '', value: 'loungebank' },
//           { count: 1, data: {}, displayName: 'sets', status: '', value: 'sets' },
//           { count: 1, data: {}, displayName: 'picknicktafel', status: '', value: 'picknicktafel' },
//           { count: 1, data: {}, displayName: 'picknick', status: '', value: 'picknick' },
//           { count: 1, data: {}, displayName: 'ligbed', status: '', value: 'ligbed' },
//           { count: 1, data: {}, displayName: 'ligbank', status: '', value: 'ligbank' },
//           { count: 1, data: {}, displayName: 'houten tuinmeubel', status: '', value: 'houten tuinmeubel' },
//           { count: 1, data: {}, displayName: 'witten plafond', status: '', value: 'witten plafond' },
//           { count: 1, data: {}, displayName: 'verf plafond', status: '', value: 'verf plafond' },
//           { count: 1, data: {}, displayName: 'plafond witten', status: '', value: 'plafond witten' },
//           { count: 1, data: {}, displayName: 'plafond verven', status: '', value: 'plafond verven' },
//           { count: 3, data: {}, displayName: 'opbergen', status: '', value: 'opbergen' },
//           { count: 2, data: {}, displayName: 'schuifdeur', status: '', value: 'schuifdeur' },
//           { count: 2, data: {}, displayName: 'opbergruimte', status: '', value: 'opbergruimte' },
//           { count: 2, data: {}, displayName: 'deuren', status: '', value: 'deuren' },
//           { count: 1, data: {}, displayName: 'zolder', status: '', value: 'zolder' },
//           { count: 1, data: {}, displayName: 'schuifdeuren', status: '', value: 'schuifdeuren' },
//           { count: 7, data: {}, displayName: 'verwarming', status: '', value: 'verwarming' },
//           { count: 6, data: {}, displayName: 'kachel', status: '', value: 'kachel' },
//           { count: 2, data: {}, displayName: 'elektrische kachel', status: '', value: 'elektrische kachel' },
//           { count: 1, data: {}, displayName: 'ventilatorkachel', status: '', value: 'ventilatorkachel' },
//           { count: 1, data: {}, displayName: 'infraroodpaneel', status: '', value: 'infraroodpaneel' },
//           { count: 1, data: {}, displayName: 'infraroodkachel', status: '', value: 'infraroodkachel' },
//           { count: 1, data: {}, displayName: 'electrische kachel', status: '', value: 'electrische kachel' },
//           { count: 1, data: {}, displayName: 'convector', status: '', value: 'convector' },
//           { count: 2, data: {}, displayName: 'schuurmachine', status: '', value: 'schuurmachine' },
//           { count: 2, data: {}, displayName: 'metaal', status: '', value: 'metaal' },
//           { count: 1, data: {}, displayName: 'zaagtafel', status: '', value: 'zaagtafel' },
//           { count: 1, data: {}, displayName: 'zaagmachine', status: '', value: 'zaagmachine' },
//           { count: 1, data: {}, displayName: 'zaagblad', status: '', value: 'zaagblad' },
//           { count: 1, data: {}, displayName: 'takken', status: '', value: 'takken' },
//           { count: 1, data: {}, displayName: 'sloopzaag', status: '', value: 'sloopzaag' },
//           { count: 1, data: {}, displayName: 'reciprozaag', status: '', value: 'reciprozaag' },
//           { count: 1, data: {}, displayName: 'kunststof', status: '', value: 'kunststof' },
//           { count: 1, data: {}, displayName: 'kettingzaag', status: '', value: 'kettingzaag' },
//           { count: 1, data: {}, displayName: 'karton', status: '', value: 'karton' },
//           { count: 1, data: {}, displayName: 'invalzaag', status: '', value: 'invalzaag' },
//           { count: 1, data: {}, displayName: 'geleiderail', status: '', value: 'geleiderail' },
//           { count: 1, data: {}, displayName: 'geleider', status: '', value: 'geleider' },
//           { count: 1, data: {}, displayName: 'decoupeerzaag', status: '', value: 'decoupeerzaag' },
//           { count: 1, data: {}, displayName: 'cirkelzaag', status: '', value: 'cirkelzaag' },
//           { count: 1, data: {}, displayName: 'buizen', status: '', value: 'buizen' },
//           { count: 1, data: {}, displayName: 'balken', status: '', value: 'balken' },
//           { count: 1, data: {}, displayName: 'accu', status: '', value: 'accu' },
//           { count: 5, data: {}, displayName: 'kamerplant', status: '', value: 'kamerplant' },
//           { count: 3, data: {}, displayName: 'kamerplanten', status: '', value: 'kamerplanten' },
//           { count: 3, data: {}, displayName: 'aarde', status: '', value: 'aarde' },
//           { count: 1, data: {}, displayName: 'potten', status: '', value: 'potten' },
//           { count: 7, data: {}, displayName: 'buitenkeuken', status: '', value: 'buitenkeuken' },
//           { count: 5, data: {}, displayName: 'barbecue', status: '', value: 'barbecue' },
//           { count: 4, data: {}, displayName: 'bbq', status: '', value: 'bbq' },
//           { count: 1, data: {}, displayName: 'Rookoven', status: '', value: 'Rookoven' },
//           { count: 1, data: {}, displayName: 'houtskool', status: '', value: 'houtskool' },
//           { count: 1, data: {}, displayName: 'briquettes', status: '', value: 'briquettes' },
//           { count: 1, data: {}, displayName: 'briketten', status: '', value: 'briketten' },
//           { count: 1, data: {}, displayName: 'BBQ', status: '', value: 'BBQ' },
//           { count: 1, data: {}, displayName: 'barbecueën', status: '', value: 'barbecueën' },
//           { count: 1, data: {}, displayName: 'barbecues', status: '', value: 'barbecues' },
//           { count: 6, data: {}, displayName: 'gazon', status: '', value: 'gazon' },
//           { count: 5, data: {}, displayName: 'gras', status: '', value: 'gras' },
//           { count: 4, data: {}, displayName: 'grasmaaier', status: '', value: 'grasmaaier' },
//           { count: 2, data: {}, displayName: 'grasmaaien', status: '', value: 'grasmaaien' },
//           { count: 1, data: {}, displayName: 'tuinonderhoud', status: '', value: 'tuinonderhoud' },
//           { count: 1, data: {}, displayName: 'robot grasmaaier', status: '', value: 'robot grasmaaier' },
//           { count: 1, data: {}, displayName: 'maaier', status: '', value: 'maaier' },
//           { count: 1, data: {}, displayName: 'handgrasmaaier', status: '', value: 'handgrasmaaier' },
//           { count: 1, data: {}, displayName: 'grasveld', status: '', value: 'grasveld' },
//           { count: 1, data: {}, displayName: 'elektrische grasmaaier', status: '', value: 'elektrische grasmaaier' },
//           { count: 4, data: {}, displayName: 'onkruid', status: '', value: 'onkruid' },
//           { count: 3, data: {}, displayName: 'onkruidsteker', status: '', value: 'onkruidsteker' },
//           { count: 2, data: {}, displayName: 'mest', status: '', value: 'mest' },
//           { count: 1, data: {}, displayName: 'graszaad', status: '', value: 'graszaad' },
//           { count: 1, data: {}, displayName: 'grasmat', status: '', value: 'grasmat' },
//           { count: 1, data: {}, displayName: 'gazonmest', status: '', value: 'gazonmest' },
//           { count: 1, data: {}, displayName: 'bestrijdingsmiddel', status: '', value: 'bestrijdingsmiddel' },
//           { count: 1, data: {}, displayName: 'rol gordijn', status: '', value: 'rol gordijn' },
//           { count: 1, data: {}, displayName: 'rolgordijnen inkorten', status: '', value: 'rolgordijnen inkorten' },
//           {
//             count: 1,
//             data: {},
//             displayName: 'rolgordijn verduisterend',
//             status: '',
//             value: 'rolgordijn verduisterend'
//           },
//           { count: 1, data: {}, displayName: 'rolgordijn inkorten', status: '', value: 'rolgordijn inkorten' },
//           { count: 1, data: {}, displayName: 'raambekleding', status: '', value: 'raambekleding' },
//           { count: 5, data: {}, displayName: 'pvc vloer', status: '', value: 'pvc vloer' },
//           { count: 1, data: {}, displayName: 'vloer leggen', status: '', value: 'vloer leggen' },
//           { count: 2, data: {}, displayName: 'tuinhuisje', status: '', value: 'tuinhuisje' },
//           { count: 1, data: {}, displayName: 'tuinhuis', status: '', value: 'tuinhuis' },
//           { count: 1, data: {}, displayName: 'blokhut', status: '', value: 'blokhut' },
//           { count: 1, data: {}, displayName: 'zonwering tuin', status: '', value: 'zonwering tuin' },
//           { count: 1, data: {}, displayName: 'zonwering balkon', status: '', value: 'zonwering balkon' },
//           { count: 8, data: {}, displayName: 'duurzaam', status: '', value: 'duurzaam' },
//           { count: 1, data: {}, displayName: 'spotjes', status: '', value: 'spotjes' },
//           { count: 1, data: {}, displayName: 'led lamp vervangen', status: '', value: 'led lamp vervangen' },
//           { count: 1, data: {}, displayName: 'lampje vervangen', status: '', value: 'lampje vervangen' },
//           { count: 1, data: {}, displayName: 'lamp vervangen', status: '', value: 'lamp vervangen' },
//           { count: 1, data: {}, displayName: 'halogeen lamp vervangen', status: '', value: 'halogeen lamp vervangen' },
//           { count: 1, data: {}, displayName: 'halogeen lamp', status: '', value: 'halogeen lamp' },
//           { count: 1, data: {}, displayName: 'pot', status: '', value: 'pot' },
//           { count: 1, data: {}, displayName: 'voegenkrabber', status: '', value: 'voegenkrabber' },
//           { count: 1, data: {}, displayName: 'onkruidverwijderaar', status: '', value: 'onkruidverwijderaar' },
//           { count: 1, data: {}, displayName: 'onkruidverdelger', status: '', value: 'onkruidverdelger' },
//           { count: 1, data: {}, displayName: 'onkruidbrander', status: '', value: 'onkruidbrander' },
//           { count: 1, data: {}, displayName: 'onkruid verwijderaar', status: '', value: 'onkruid verwijderaar' },
//           { count: 2, data: {}, displayName: 'schuurpapier', status: '', value: 'schuurpapier' },
//           { count: 1, data: {}, displayName: 'stofafzuiging', status: '', value: 'stofafzuiging' },
//           { count: 1, data: {}, displayName: 'slijpen', status: '', value: 'slijpen' },
//           { count: 1, data: {}, displayName: 'schuren', status: '', value: 'schuren' },
//           { count: 1, data: {}, displayName: 'polijsten', status: '', value: 'polijsten' },
//           { count: 1, data: {}, displayName: 'plaatmateriaal', status: '', value: 'plaatmateriaal' },
//           { count: 1, data: {}, displayName: 'multitool', status: '', value: 'multitool' },
//           { count: 1, data: {}, displayName: 'multischuurmachine', status: '', value: 'multischuurmachine' },
//           { count: 1, data: {}, displayName: 'handpalmschuurmachine', status: '', value: 'handpalmschuurmachine' },
//           { count: 1, data: {}, displayName: 'handpalm', status: '', value: 'handpalm' },
//           {
//             count: 1,
//             data: {},
//             displayName: 'excentrische schuurmachine',
//             status: '',
//             value: 'excentrische schuurmachine'
//           },
//           { count: 1, data: {}, displayName: 'deltaschuurmachine', status: '', value: 'deltaschuurmachine' },
//           { count: 2, data: {}, displayName: 'meubel', status: '', value: 'meubel' },
//           { count: 1, data: {}, displayName: 'meubel beitsen', status: '', value: 'meubel beitsen' },
//           { count: 1, data: {}, displayName: 'keuken tuin', status: '', value: 'keuken tuin' },
//           { count: 1, data: {}, displayName: 'keuken buiten', status: '', value: 'keuken buiten' },
//           { count: 1, data: {}, displayName: 'buitenkeuken beton', status: '', value: 'buitenkeuken beton' },
//           { count: 1, data: {}, displayName: 'barbecue bouwen', status: '', value: 'barbecue bouwen' },
//           { count: 1, data: {}, displayName: 'luchtkoeler', status: '', value: 'luchtkoeler' },
//           { count: 1, data: {}, displayName: 'aircooler', status: '', value: 'aircooler' },
//           { count: 1, data: {}, displayName: 'air cooler', status: '', value: 'air cooler' },
//           { count: 5, data: {}, displayName: 'regenton', status: '', value: 'regenton' },
//           { count: 2, data: {}, displayName: 'bewatering', status: '', value: 'bewatering' },
//           { count: 1, data: {}, displayName: 'regenwater', status: '', value: 'regenwater' },
//           { count: 1, data: {}, displayName: 'regentonkraan', status: '', value: 'regentonkraan' },
//           { count: 1, data: {}, displayName: 'regenton aansluiten', status: '', value: 'regenton aansluiten' },
//           { count: 1, data: {}, displayName: 'regenpijp', status: '', value: 'regenpijp' },
//           { count: 1, data: {}, displayName: 'aansluiting regenton', status: '', value: 'aansluiting regenton' },
//           { count: 2, data: {}, displayName: 'voorstrijk', status: '', value: 'voorstrijk' },
//           { count: 1, data: {}, displayName: 'voorstrijken', status: '', value: 'voorstrijken' },
//           { count: 1, data: {}, displayName: 'voorstrijk muur', status: '', value: 'voorstrijk muur' },
//           { count: 1, data: {}, displayName: 'voorstrijk muren', status: '', value: 'voorstrijk muren' },
//           { count: 1, data: {}, displayName: 'alabastine voorstrijk', status: '', value: 'alabastine voorstrijk' },
//           { count: 2, data: {}, displayName: 'thermostaat', status: '', value: 'thermostaat' },
//           { count: 2, data: {}, displayName: 'douchekraan', status: '', value: 'douchekraan' },
//           {
//             count: 1,
//             data: {},
//             displayName: 'thermostaatkraan vervangen',
//             status: '',
//             value: 'thermostaatkraan vervangen'
//           },
//           { count: 1, data: {}, displayName: 'thermostaatkraan', status: '', value: 'thermostaatkraan' },
//           { count: 1, data: {}, displayName: 'nieuwe thermostaatkraan', status: '', value: 'nieuwe thermostaatkraan' },
//           { count: 1, data: {}, displayName: 'douchekraan vervangen', status: '', value: 'douchekraan vervangen' }
//         ],
//         type: 'multiple',
//         data: {},
//         hidden: false
//       }
//     ],
//     groups: [],
//     results: [
//       {
//         value: 'Laminaat leggen',
//         data: {
//           description:
//             'Wil je zelf een laminaat vloer leggen? In dit stappenplan laten we zien hoe je laminaat legt. We leggen uit hoe je een ondervloer plaatst, laminaat legt en verwarmingsbuizen omzeilt.',
//           id: '7f175ac3-0e05-409b-a04e-49ee665452ce',
//           url: 'https://www.praxis.nl/klusadvies/stappenplan/laminaat-leggen',
//           imageUrl: 'https://img.youtube.com/vi/hPfwxGXLiGQ/0.jpg',
//           metadata: {
//             character: 'youtube',
//             attribute_duration: '1 day',
//             publishDateTime: '20240905134320',
//             attribute_difficulty: 'medium',
//             type: 'step-by-step',
//             attribute_cost: 'average'
//           }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'Een muur glad maken zonder te stucen',
//         data: {
//           description:
//             'Wil je een muur glad maken zonder stucen? Bekijk de video en maak met muurglad ondergronden zoals glasvezelbehang en gipsblokken in één dag egaal!',
//           id: 'c24c8a84-3703-4164-b31c-abf131182a1c',
//           url: 'https://www.praxis.nl/klusadvies/stappenplan/een-muur-glad-maken-zonder-te-stucen',
//           imageUrl: 'https://img.youtube.com/vi/_jsKlf8KOJM/0.jpg',
//           metadata: {
//             character: 'youtube',
//             attribute_duration: 'less than a day',
//             publishDateTime: '20240905132201',
//             attribute_difficulty: 'medium',
//             type: 'step-by-step',
//             attribute_cost: 'average'
//           }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'Verhuistips en -checklist',
//         data: {
//           description:
//             'Bij een verhuizing moet veel geregeld worden. Wij helpen je graag op weg met een handige checklist verhuizen en handige verhuistips.',
//           id: 'M1R6WDXJoA6tsigl0suta',
//           url: 'https://www.praxis.nl/klusadvies/klustip/verhuistips-en-checklist',
//           imageUrl:
//             'https://images.ctfassets.net/0nvfg2ncafkj/5ldU0DmTeYEOsd0ra7KF8J/7a7b173c306b8660a86b6d70d48de3f3/verhuisdozen.jpg',
//           metadata: { character: 'cover', publishDateTime: '20240905092332', type: 'diy-tip' }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'Op kamers: klustips en ideeën om slim in te richten',
//         data: {
//           description:
//             'Ga je op kamers? Dan heb je veel te doen. En daar helpen we je graag bij! Hier vind je handige tips en tools voor bij het klussen, ideeën om je kamer slim in te delen en natuurlijk veel leuke inspiratie voor je interieur – voor behang en verf ...',
//           id: '42YJ1lIPwmPUN71N5iymNF',
//           url: 'https://www.praxis.nl/inspiratie/klussen-inrichten-studentenkamer',
//           imageUrl:
//             'https://images.ctfassets.net/0nvfg2ncafkj/2bYa7Zf7M2WaoXFOim2PAz/ead27c737f8e53317ac6ed45f73ea43f/Ontwerp_zonder_titel__3_.png',
//           metadata: {
//             attribute_timeToRead: '4 minuten',
//             character: 'cover',
//             publishDateTime: '20240905062559',
//             type: 'blog'
//           }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'Alles over badkamerverlichting',
//         data: {
//           description:
//             'Voor het verlichten van een badkamer zijn er veel mogelijkheden. De functionaliteit van de badkamer en de hoeveelheid licht zijn belangrijk bij het maken van een keuze. Wij helpen je graag op weg met het kiezen van de juiste verlichting.',
//           id: '7dGva0LZ3lzQft0A82jxcc',
//           url: 'https://www.praxis.nl/productadvies/badkamerverlichting',
//           imageUrl:
//             'https://images.ctfassets.net/0nvfg2ncafkj/5lbKWsfPTW75mkOTcSOupO/0b932dfa70b880e8a1fe27ef372e8f6d/badkamerverlichting.jpg',
//           metadata: { character: 'cover', publishDateTime: '20240904095953', type: 'product-advice' }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'Kies de juiste nieten',
//         data: {
//           description:
//             'Heb je een nietmachine, maar twijfel je over wat de juiste nieten zijn? Wij leggen uit waar je op moet letten bij het aanschaffen van nietjes.',
//           id: 'ecdc8013-df8c-4853-8843-6b2423d6102d',
//           url: 'https://www.praxis.nl/klusadvies/klustip/advies-nieten',
//           imageUrl:
//             'https://images.ctfassets.net/0nvfg2ncafkj/7yQAfYLJ4XbLBZ4C878e4b/b537ca19f4bf63b9a42a9ad9d5f9fb73/nietmachine-header940x940.jpg',
//           metadata: { character: 'cover', publishDateTime: '20240826163637', type: 'diy-tip' }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'Gazon onderhoud',
//         data: {
//           description:
//             'Houd je gazon gezond en groen met onze tips voor gazon onderhoud! Omdat je niet zomaar een strakke, groene grasmat krijgt, hebben wij een handig gazon onderhoud schema opgesteld. Zo weet je precies wat je moet doen om je gazon te laten stralen. ...',
//           id: 'f89e03ef-6889-411f-a3da-20dc9c53b14e',
//           url: 'https://www.praxis.nl/klusadvies/klustip/gazon-onderhoud',
//           imageUrl:
//             'https://images.ctfassets.net/0nvfg2ncafkj/2Ny35m9NgRXO4qWtYUaQmq/a72f9ba5992e5334c7919c2c7910ffd9/gazon_achtertuin.jpg',
//           metadata: { character: 'cover', publishDateTime: '20240826163636', type: 'diy-tip' }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'Ondervloer leggen',
//         data: {
//           description:
//             'Wil je zelf een ondervloer leggen? Check dan eerst hoeveel oneffenheden er in de vloer zitten, van welk materiaal je ondervloer is en hoeveel geluidsisolatie je wilt. Wij leggen je dan uit welke ondervloer je het beste kunt gebruiken. En laten ...',
//           id: 'da9e7023-d881-44ad-935f-b794f80a9719',
//           url: 'https://www.praxis.nl/klusadvies/klustip/laminaat-ondervloer',
//           imageUrl:
//             'https://images.ctfassets.net/0nvfg2ncafkj/3YVZmenqKk5h9ghWnnzBK4/beff89141089761ab12923d407aec167/Screenshot_2020-06-11_at_08.08.12_Header_op_landingspagina-s_940x940.jpg',
//           metadata: { character: 'cover', publishDateTime: '20240826163635', type: 'diy-tip' }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'Hoeveel liter verf per m2 heb je nodig?',
//         data: {
//           description:
//             'Weet jij niet zo goed hoeveel liter verf je voor jouw verfklus nodig hebt? Geen zorgen! Wij leggen je uit hoe je de hoeveelheid liters verf per vierkante meter berekent.',
//           id: '1d124903-8e30-4b7a-9fe2-7c6b7745b6d9',
//           url: 'https://www.praxis.nl/klusadvies/klustip/hoeveelheid-verf-berekenen',
//           imageUrl:
//             'https://images.ctfassets.net/0nvfg2ncafkj/Uu4a8z9KakTAhFCopKj98/11db7eae28ca6fa696d9e563a173e2c5/verfblikkenperm940x940.jpg',
//           metadata: { character: 'cover', publishDateTime: '20240826163635', type: 'diy-tip' }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'Lichtplan maken',
//         data: {
//           description:
//             'Door een lichtplan te maken, leg je vast waar je verlichting gaat plaatsen. Zo is op de juiste plekken voldoende licht aanwezig. Dan is het licht in de ruimte niet alleen sfeervol, maar ook zeer functioneel. Of je nu op zoek bent naar een ...',
//           id: 'b500b517-ba85-49ea-9fc6-d89013aaaf10',
//           url: 'https://www.praxis.nl/klusadvies/klustip/lichtplan-maken',
//           imageUrl:
//             'https://images.ctfassets.net/0nvfg2ncafkj/5GkB7pJS2bdRGSUUe56Vm6/813787131831f398fe8dbd0888754e1a/10043028_s02_Header_op_landingspagina-s_940x940.jpg',
//           metadata: { character: 'cover', publishDateTime: '20240826163633', type: 'diy-tip' }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'Schaduwdoek bevestigen',
//         data: {
//           description:
//             'Wil je een schaduwdoek ophangen in je tuin of op je balkon en kun je wel wat hulp gebruiken? Wij leggen uit hoe je een schaduwdoek het beste kunt monteren, vertellen je wat je daarvoor nodig hebt en geven je handige tips. Zo geniet jij straks van ...',
//           id: 'aadfe69d-dec3-4fbd-ae33-68c78f0459be',
//           url: 'https://www.praxis.nl/klusadvies/klustip/schaduwdoek-bevestigen',
//           imageUrl:
//             'https://images.ctfassets.net/0nvfg2ncafkj/5ULGz0qcukTHQeqAqVi0JS/da5b5bc787fe44b8700fce7043841f54/schaduwdoek_beige.jpg',
//           metadata: { character: 'cover', publishDateTime: '20240826163632', type: 'diy-tip' }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'PVC vloer verwijderen',
//         data: {
//           description:
//             'Wil je een nieuwe vloer en de oude PVC vloer verwijderen? Bekijk dan altijd eerst of je de nieuwe vloer niet over de oude heen kan leggen. Kan dat niet door bijvoorbeeld vloerverwarming? Dan leggen wij je uit hoe (en wanneer) je zelf een PVC vloer ...',
//           id: '3275abc3-d64c-48e1-bc20-84439c624be4',
//           url: 'https://www.praxis.nl/klusadvies/klustip/pvc-vloer-verwijderen',
//           imageUrl:
//             'https://images.ctfassets.net/0nvfg2ncafkj/5TFxafvM9VJ8L26DMwZtiT/afa9dd238cc48a9206aa14c8f0f1f949/vloerverwijdern.jpg',
//           metadata: { character: 'cover', publishDateTime: '20240826163632', type: 'diy-tip' }
//         },
//         matchedTerms: [],
//         labels: {}
//       }
//     ],
//     totalNumberOfResults: 412,
//     sortOptions: [
//       { displayName: 'Most Relevant', sortBy: 'relevance', sortOrder: 'descending', status: '' },
//       { displayName: 'Nouveautés', sortBy: 'publishDateTime', sortOrder: 'descending', status: 'selected' }
//     ],
//     resultSources: { tokenMatch: { count: 412 }, embeddingsMatch: { count: 0 } },
//     refinedContent: []
//   },
//   request: {
//     page: 1,
//     num_results_per_page: 12,
//     sort_by: 'publishDateTime',
//     sort_order: 'descending',
//     filters: {
//       valid: ['1'],
//       pageType: ['blog', 'diy-tip', 'product-advice', 'step-by-step'],
//       tags: ['Product advice', 'DIY-tip', 'Step-by-Step', 'Blog page type']
//     },
//     section: 'Soft_Content',
//     term: '',
//     fmt_options: {
//       groups_start: 'current',
//       groups_max_depth: 1,
//       show_hidden_facets: false,
//       show_hidden_fields: false,
//       show_protected_facets: false
//     },
//     features: {
//       query_items: true,
//       a_a_test: false,
//       auto_generated_refined_query_rules: true,
//       manual_searchandizing: true,
//       personalization: true,
//       filter_items: true,
//       use_reranker_service_for_search: true,
//       use_reranker_service_for_browse: true,
//       use_reranker_service_for_all: false,
//       custom_autosuggest_ui: false,
//       disable_test_only_global_rules_search: false,
//       disable_test_only_global_rules_browse: false,
//       use_enriched_attributes_as_fuzzy_searchable: true
//     },
//     feature_variants: {
//       query_items: 'query_items_ctr_and_l2r',
//       auto_generated_refined_query_rules: 'default_rules',
//       personalization: 'default_personalization',
//       filter_items: 'filter_items_w_atcs_and_purchases',
//       use_reranker_service_for_search: 'search_reranker_pca_token_item_top_100',
//       use_reranker_service_for_browse: 'browse_reranker_wo_context_cond_top100'
//     },
//     filter_match_types: { valid: 'any', pageType: 'any', tags: 'any' },
//     searchandized_items: {},
//     browse_filter_name: 'valid',
//     browse_filter_value: '1'
//   }
// }
// const responseData2 = {
//   resultId: '8140e46f-6dd1-4b4e-9cc0-1c37de4514ad',
//   response: {
//     facets: [
//       {
//         displayName: 'valid',
//         name: 'valid',
//         options: [{ count: 51, data: {}, displayName: '1', status: 'selected', value: '1' }],
//         type: 'multiple',
//         data: {},
//         hidden: false
//       },
//       {
//         displayName: 'pageType',
//         name: 'pageType',
//         options: [
//           { count: 51, data: {}, displayName: 'product-advice', status: 'selected', value: 'product-advice' },
//           { count: 1, data: {}, displayName: 'loyalty-page', status: '', value: 'loyalty-page' }
//         ],
//         type: 'multiple',
//         data: {},
//         hidden: false
//       },
//       {
//         displayName: 'tags',
//         name: 'tags',
//         options: [
//           {
//             count: 53,
//             data: {},
//             displayName: 'customer journey tag shopping',
//             status: '',
//             value: 'customer journey tag shopping'
//           },
//           { count: 51, data: {}, displayName: 'Product advice', status: 'selected', value: 'Product advice' },
//           { count: 19, data: {}, displayName: 'Season summer', status: '', value: 'Season summer' },
//           { count: 13, data: {}, displayName: 'Floors', status: '', value: 'Floors' },
//           { count: 11, data: {}, displayName: 'Laminate', status: '', value: 'Laminate' },
//           { count: 10, data: {}, displayName: 'garden-world', status: '', value: 'garden-world' },
//           { count: 9, data: {}, displayName: 'Season spring', status: '', value: 'Season spring' },
//           { count: 8, data: {}, displayName: 'Season winter', status: '', value: 'Season winter' },
//           { count: 6, data: {}, displayName: 'Season fall', status: '', value: 'Season fall' },
//           { count: 6, data: {}, displayName: 'Garden tools', status: '', value: 'Garden tools' },
//           { count: 5, data: {}, displayName: 'Toilet', status: '', value: 'Toilet' },
//           { count: 5, data: {}, displayName: 'Paint- stain', status: '', value: 'Paint- stain' },
//           { count: 4, data: {}, displayName: 'swimming pool', status: '', value: 'swimming pool' },
//           { count: 4, data: {}, displayName: 'Sustainable', status: '', value: 'Sustainable' },
//           { count: 4, data: {}, displayName: 'paint-primer', status: '', value: 'paint-primer' },
//           { count: 4, data: {}, displayName: 'paint-metal', status: '', value: 'paint-metal' },
//           { count: 4, data: {}, displayName: 'paint-latex', status: '', value: 'paint-latex' },
//           { count: 4, data: {}, displayName: 'paint-lacquer', status: '', value: 'paint-lacquer' },
//           { count: 4, data: {}, displayName: 'paint-alkyd', status: '', value: 'paint-alkyd' },
//           { count: 4, data: {}, displayName: 'paint-acrylic', status: '', value: 'paint-acrylic' },
//           { count: 4, data: {}, displayName: 'Paint types', status: '', value: 'Paint types' },
//           { count: 4, data: {}, displayName: 'paint -varnish', status: '', value: 'paint -varnish' },
//           { count: 4, data: {}, displayName: 'Heating', status: '', value: 'Heating' },
//           { count: 4, data: {}, displayName: 'Christmas', status: '', value: 'Christmas' },
//           { count: 3, data: {}, displayName: 'test4r', status: '', value: 'test4r' },
//           { count: 3, data: {}, displayName: 'Potting soil', status: '', value: 'Potting soil' },
//           { count: 3, data: {}, displayName: 'Insulation material', status: '', value: 'Insulation material' },
//           { count: 2, data: {}, displayName: 'Step-by-step', status: '', value: 'Step-by-step' },
//           { count: 2, data: {}, displayName: 'Plants', status: '', value: 'Plants' },
//           { count: 2, data: {}, displayName: 'paint ral-9010', status: '', value: 'paint ral-9010' },
//           { count: 2, data: {}, displayName: 'paint ral-9001', status: '', value: 'paint ral-9001' },
//           { count: 2, data: {}, displayName: 'Electra', status: '', value: 'Electra' },
//           { count: 2, data: {}, displayName: 'Drills', status: '', value: 'Drills' },
//           { count: 2, data: {}, displayName: 'Do it for me', status: '', value: 'Do it for me' },
//           { count: 2, data: {}, displayName: 'balcony', status: '', value: 'balcony' },
//           { count: 1, data: {}, displayName: 'test5', status: '', value: 'test5' },
//           { count: 1, data: {}, displayName: 'test3', status: '', value: 'test3' },
//           { count: 1, data: {}, displayName: 'TAG!', status: '', value: 'TAG!' },
//           { count: 1, data: {}, displayName: 'Skirting boards', status: '', value: 'Skirting boards' },
//           { count: 1, data: {}, displayName: 'paint-textured', status: '', value: 'paint-textured' },
//           {
//             count: 1,
//             data: {},
//             displayName: 'multiple_hero_image_test',
//             status: '',
//             value: 'multiple_hero_image_test'
//           },
//           { count: 1, data: {}, displayName: 'Leaf blowers', status: '', value: 'Leaf blowers' },
//           { count: 1, data: {}, displayName: 'Indoor lighting', status: '', value: 'Indoor lighting' },
//           { count: 1, data: {}, displayName: 'Aircooler', status: '', value: 'Aircooler' }
//         ],
//         type: 'multiple',
//         data: {},
//         hidden: false
//       },
//       {
//         displayName: 'keywords',
//         name: 'keywords',
//         options: [
//           { count: 7, data: {}, displayName: 'verf', status: '', value: 'verf' },
//           { count: 6, data: {}, displayName: 'verven', status: '', value: 'verven' },
//           { count: 6, data: {}, displayName: 'schilderen', status: '', value: 'schilderen' },
//           { count: 5, data: {}, displayName: 'hout', status: '', value: 'hout' },
//           { count: 3, data: {}, displayName: 'zwembad', status: '', value: 'zwembad' },
//           { count: 3, data: {}, displayName: 'witte verf', status: '', value: 'witte verf' },
//           { count: 3, data: {}, displayName: 'verwarming', status: '', value: 'verwarming' },
//           { count: 3, data: {}, displayName: 'tuin', status: '', value: 'tuin' },
//           { count: 3, data: {}, displayName: 'potgrond', status: '', value: 'potgrond' },
//           { count: 3, data: {}, displayName: 'planten', status: '', value: 'planten' },
//           { count: 3, data: {}, displayName: 'kachel', status: '', value: 'kachel' },
//           { count: 3, data: {}, displayName: 'jacuzzi', status: '', value: 'jacuzzi' },
//           { count: 2, data: {}, displayName: 'zonwering', status: '', value: 'zonwering' },
//           { count: 2, data: {}, displayName: 'wastafel', status: '', value: 'wastafel' },
//           { count: 2, data: {}, displayName: 'vloeren', status: '', value: 'vloeren' },
//           { count: 2, data: {}, displayName: 'vloer', status: '', value: 'vloer' },
//           { count: 2, data: {}, displayName: 'verlichting', status: '', value: 'verlichting' },
//           { count: 2, data: {}, displayName: 'tuinmeubels', status: '', value: 'tuinmeubels' },
//           { count: 2, data: {}, displayName: 'tuinhout', status: '', value: 'tuinhout' },
//           { count: 2, data: {}, displayName: 'tuinbank', status: '', value: 'tuinbank' },
//           { count: 2, data: {}, displayName: 'tegels', status: '', value: 'tegels' },
//           { count: 2, data: {}, displayName: 'steigerhout', status: '', value: 'steigerhout' },
//           { count: 2, data: {}, displayName: 'sloten', status: '', value: 'sloten' },
//           { count: 2, data: {}, displayName: 'schuurmachine', status: '', value: 'schuurmachine' },
//           { count: 2, data: {}, displayName: 'schutting', status: '', value: 'schutting' },
//           { count: 2, data: {}, displayName: 'plant', status: '', value: 'plant' },
//           { count: 2, data: {}, displayName: 'planken', status: '', value: 'planken' },
//           { count: 2, data: {}, displayName: 'plank', status: '', value: 'plank' },
//           { count: 2, data: {}, displayName: 'overkapping', status: '', value: 'overkapping' },
//           { count: 2, data: {}, displayName: 'opzetzwembad', status: '', value: 'opzetzwembad' },
//           { count: 2, data: {}, displayName: 'opblaaszwembad', status: '', value: 'opblaaszwembad' },
//           { count: 2, data: {}, displayName: 'muurverf', status: '', value: 'muurverf' },
//           { count: 2, data: {}, displayName: 'loungeset', status: '', value: 'loungeset' },
//           { count: 2, data: {}, displayName: 'laminaat', status: '', value: 'laminaat' },
//           { count: 2, data: {}, displayName: 'krijtverf', status: '', value: 'krijtverf' },
//           { count: 2, data: {}, displayName: 'kraan', status: '', value: 'kraan' },
//           { count: 2, data: {}, displayName: 'hottub', status: '', value: 'hottub' },
//           { count: 2, data: {}, displayName: 'filterpomp', status: '', value: 'filterpomp' },
//           { count: 2, data: {}, displayName: 'deur', status: '', value: 'deur' },
//           { count: 2, data: {}, displayName: 'bloemen', status: '', value: 'bloemen' },
//           { count: 2, data: {}, displayName: 'beitsen', status: '', value: 'beitsen' },
//           { count: 2, data: {}, displayName: 'beits', status: '', value: 'beits' },
//           { count: 2, data: {}, displayName: 'badkamer', status: '', value: 'badkamer' },
//           { count: 2, data: {}, displayName: 'airconditioning', status: '', value: 'airconditioning' },
//           { count: 2, data: {}, displayName: 'airco', status: '', value: 'airco' },
//           { count: 2, data: {}, displayName: 'aarde', status: '', value: 'aarde' },
//           { count: 1, data: {}, displayName: 'zonnescherm', status: '', value: 'zonnescherm' },
//           { count: 1, data: {}, displayName: 'zonnedoek', status: '', value: 'zonnedoek' },
//           { count: 1, data: {}, displayName: 'zachthout', status: '', value: 'zachthout' },
//           { count: 1, data: {}, displayName: 'zaagtafel', status: '', value: 'zaagtafel' },
//           { count: 1, data: {}, displayName: 'zaagblad', status: '', value: 'zaagblad' },
//           { count: 1, data: {}, displayName: 'wpc composiet', status: '', value: 'wpc composiet' },
//           { count: 1, data: {}, displayName: 'woonkamer', status: '', value: 'woonkamer' },
//           { count: 1, data: {}, displayName: 'wc', status: '', value: 'wc' },
//           { count: 1, data: {}, displayName: 'waterbasis', status: '', value: 'waterbasis' },
//           { count: 1, data: {}, displayName: 'waskom', status: '', value: 'waskom' },
//           { count: 1, data: {}, displayName: 'warmtekanon', status: '', value: 'warmtekanon' },
//           { count: 1, data: {}, displayName: 'warm witte verf', status: '', value: 'warm witte verf' },
//           { count: 1, data: {}, displayName: 'wandtegel', status: '', value: 'wandtegel' },
//           { count: 1, data: {}, displayName: 'wandlamp', status: '', value: 'wandlamp' },
//           { count: 1, data: {}, displayName: 'wandbekleding', status: '', value: 'wandbekleding' },
//           { count: 1, data: {}, displayName: 'vouwgordijn', status: '', value: 'vouwgordijn' },
//           { count: 1, data: {}, displayName: 'voorzethor', status: '', value: 'voorzethor' },
//           { count: 1, data: {}, displayName: 'voordeur', status: '', value: 'voordeur' },
//           { count: 1, data: {}, displayName: 'vlonderplank', status: '', value: 'vlonderplank' },
//           { count: 1, data: {}, displayName: 'vlonder', status: '', value: 'vlonder' },
//           { count: 1, data: {}, displayName: 'vloertegel', status: '', value: 'vloertegel' },
//           { count: 1, data: {}, displayName: 'vloerlamp', status: '', value: 'vloerlamp' },
//           { count: 1, data: {}, displayName: 'vlieggordijn', status: '', value: 'vlieggordijn' },
//           { count: 1, data: {}, displayName: 'vliegengordijn', status: '', value: 'vliegengordijn' },
//           { count: 1, data: {}, displayName: 'visgraat vloer', status: '', value: 'visgraat vloer' },
//           { count: 1, data: {}, displayName: 'viool', status: '', value: 'viool' },
//           { count: 1, data: {}, displayName: 'verwarmen', status: '', value: 'verwarmen' },
//           { count: 1, data: {}, displayName: 'verfroller', status: '', value: 'verfroller' },
//           { count: 1, data: {}, displayName: 'verfkleur 9001', status: '', value: 'verfkleur 9001' },
//           { count: 1, data: {}, displayName: 'verf ral 9016', status: '', value: 'verf ral 9016' },
//           { count: 1, data: {}, displayName: 'verf ral 9010', status: '', value: 'verf ral 9010' },
//           { count: 1, data: {}, displayName: 'verf ral 9001', status: '', value: 'verf ral 9001' },
//           { count: 1, data: {}, displayName: 'verf gebroken wit', status: '', value: 'verf gebroken wit' },
//           { count: 1, data: {}, displayName: 'verduisteringsgordijn', status: '', value: 'verduisteringsgordijn' },
//           { count: 1, data: {}, displayName: 'verbena', status: '', value: 'verbena' },
//           { count: 1, data: {}, displayName: 'veranda', status: '', value: 'veranda' },
//           { count: 1, data: {}, displayName: 'ventilatorkachel', status: '', value: 'ventilatorkachel' },
//           { count: 1, data: {}, displayName: 'ventilator', status: '', value: 'ventilator' },
//           { count: 1, data: {}, displayName: 'vaste hordeur', status: '', value: 'vaste hordeur' },
//           { count: 1, data: {}, displayName: 'underlayment', status: '', value: 'underlayment' },
//           { count: 1, data: {}, displayName: 'tussendeur', status: '', value: 'tussendeur' },
//           { count: 1, data: {}, displayName: 'tuintafel', status: '', value: 'tuintafel' },
//           { count: 1, data: {}, displayName: 'tuinstoel', status: '', value: 'tuinstoel' },
//           { count: 1, data: {}, displayName: 'tuinset', status: '', value: 'tuinset' },
//           { count: 1, data: {}, displayName: 'tuinscherm', status: '', value: 'tuinscherm' },
//           { count: 1, data: {}, displayName: 'tuinplant', status: '', value: 'tuinplant' },
//           { count: 1, data: {}, displayName: 'tuinpaviljoen', status: '', value: 'tuinpaviljoen' },
//           { count: 1, data: {}, displayName: 'tuinpalen', status: '', value: 'tuinpalen' },
//           { count: 1, data: {}, displayName: 'tuinonderhoud', status: '', value: 'tuinonderhoud' },
//           { count: 1, data: {}, displayName: 'tuinmeubelen', status: '', value: 'tuinmeubelen' },
//           { count: 1, data: {}, displayName: 'tuinmeubel verven', status: '', value: 'tuinmeubel verven' },
//           { count: 1, data: {}, displayName: 'tuinhuisje', status: '', value: 'tuinhuisje' },
//           { count: 1, data: {}, displayName: 'tuinhuis', status: '', value: 'tuinhuis' },
//           { count: 1, data: {}, displayName: 'trellis', status: '', value: 'trellis' },
//           { count: 1, data: {}, displayName: 'toilet', status: '', value: 'toilet' },
//           { count: 1, data: {}, displayName: 'tochtstrip', status: '', value: 'tochtstrip' },
//           { count: 1, data: {}, displayName: 'test', status: '', value: 'test' },
//           { count: 1, data: {}, displayName: 'terrastegel', status: '', value: 'terrastegel' },
//           { count: 1, data: {}, displayName: 'terrasoverkapping', status: '', value: 'terrasoverkapping' },
//           { count: 1, data: {}, displayName: 'tegel', status: '', value: 'tegel' },
//           { count: 1, data: {}, displayName: 'takken', status: '', value: 'takken' },
//           { count: 1, data: {}, displayName: 'tafellamp', status: '', value: 'tafellamp' },
//           { count: 1, data: {}, displayName: 'structuurverf', status: '', value: 'structuurverf' },
//           { count: 1, data: {}, displayName: 'stortdouche', status: '', value: 'stortdouche' },
//           { count: 1, data: {}, displayName: 'stopcontact', status: '', value: 'stopcontact' },
//           { count: 1, data: {}, displayName: 'stofafzuiging', status: '', value: 'stofafzuiging' },
//           { count: 1, data: {}, displayName: 'steenwol', status: '', value: 'steenwol' },
//           { count: 1, data: {}, displayName: 'split airco', status: '', value: 'split airco' },
//           { count: 1, data: {}, displayName: 'split', status: '', value: 'split' },
//           { count: 1, data: {}, displayName: 'spiegelkast', status: '', value: 'spiegelkast' },
//           { count: 1, data: {}, displayName: 'spaarlamp', status: '', value: 'spaarlamp' },
//           { count: 1, data: {}, displayName: 'spaanse margriet', status: '', value: 'spaanse margriet' },
//           { count: 1, data: {}, displayName: 'spaanplaat', status: '', value: 'spaanplaat' },
//           { count: 1, data: {}, displayName: 'slot', status: '', value: 'slot' },
//           { count: 1, data: {}, displayName: 'sloopzaag', status: '', value: 'sloopzaag' },
//           { count: 1, data: {}, displayName: 'slimme verlichting', status: '', value: 'slimme verlichting' },
//           { count: 1, data: {}, displayName: 'slijpen', status: '', value: 'slijpen' },
//           { count: 1, data: {}, displayName: 'slaapkamer', status: '', value: 'slaapkamer' },
//           { count: 1, data: {}, displayName: 'shutters', status: '', value: 'shutters' },
//           { count: 1, data: {}, displayName: 'shutter', status: '', value: 'shutter' },
//           { count: 1, data: {}, displayName: 'sets', status: '', value: 'sets' },
//           { count: 1, data: {}, displayName: 'set', status: '', value: 'set' },
//           { count: 1, data: {}, displayName: 'schuurpapier', status: '', value: 'schuurpapier' },
//           { count: 1, data: {}, displayName: 'schuttingplank', status: '', value: 'schuttingplank' },
//           { count: 1, data: {}, displayName: 'schuttingen', status: '', value: 'schuttingen' },
//           { count: 1, data: {}, displayName: 'schuren', status: '', value: 'schuren' },
//           { count: 1, data: {}, displayName: 'schapenwol', status: '', value: 'schapenwol' },
//           { count: 1, data: {}, displayName: 'schakelmateriaal', status: '', value: 'schakelmateriaal' },
//           { count: 1, data: {}, displayName: 'schakelaar', status: '', value: 'schakelaar' },
//           { count: 1, data: {}, displayName: 'schaduwdoeken', status: '', value: 'schaduwdoeken' },
//           { count: 1, data: {}, displayName: 'schaduwdoek', status: '', value: 'schaduwdoek' },
//           { count: 1, data: {}, displayName: 'Rookoven', status: '', value: 'Rookoven' },
//           { count: 1, data: {}, displayName: 'roller', status: '', value: 'roller' },
//           { count: 1, data: {}, displayName: 'roljaloezieën', status: '', value: 'roljaloezieën' },
//           { count: 1, data: {}, displayName: 'rolhordeur', status: '', value: 'rolhordeur' },
//           { count: 1, data: {}, displayName: 'rolhor', status: '', value: 'rolhor' },
//           { count: 1, data: {}, displayName: 'rolgordijn', status: '', value: 'rolgordijn' },
//           { count: 1, data: {}, displayName: 'robot grasmaaier', status: '', value: 'robot grasmaaier' },
//           { count: 1, data: {}, displayName: 'regendouche', status: '', value: 'regendouche' },
//           { count: 1, data: {}, displayName: 'reciprozaag', status: '', value: 'reciprozaag' },
//           { count: 1, data: {}, displayName: 'ral 9016', status: '', value: 'ral 9016' },
//           { count: 1, data: {}, displayName: 'ral 9010', status: '', value: 'ral 9010' },
//           { count: 1, data: {}, displayName: 'ral 9001', status: '', value: 'ral 9001' },
//           { count: 1, data: {}, displayName: 'radiator', status: '', value: 'radiator' },
//           { count: 1, data: {}, displayName: 'rabatdelen', status: '', value: 'rabatdelen' },
//           { count: 1, data: {}, displayName: 'raamhorren', status: '', value: 'raamhorren' },
//           { count: 1, data: {}, displayName: 'raam', status: '', value: 'raam' },
//           { count: 1, data: {}, displayName: 'qqqqqqqqqqq', status: '', value: 'qqqqqqqqqqq' },
//           { count: 1, data: {}, displayName: 'pvc wandtegel', status: '', value: 'pvc wandtegel' },
//           { count: 1, data: {}, displayName: 'pvc', status: '', value: 'pvc' },
//           { count: 1, data: {}, displayName: 'primer', status: '', value: 'primer' },
//           { count: 1, data: {}, displayName: 'potten', status: '', value: 'potten' },
//           { count: 1, data: {}, displayName: 'pot', status: '', value: 'pot' },
//           { count: 1, data: {}, displayName: 'polijsten', status: '', value: 'polijsten' },
//           { count: 1, data: {}, displayName: 'plisséhordeur', status: '', value: 'plisséhordeur' },
//           { count: 1, data: {}, displayName: 'plisségordijn', status: '', value: 'plisségordijn' },
//           { count: 1, data: {}, displayName: 'plissé', status: '', value: 'plissé' },
//           { count: 1, data: {}, displayName: 'platen', status: '', value: 'platen' },
//           { count: 1, data: {}, displayName: 'plaktegel', status: '', value: 'plaktegel' },
//           { count: 1, data: {}, displayName: 'plafondlamp', status: '', value: 'plafondlamp' },
//           { count: 1, data: {}, displayName: 'plaatmateriaal', status: '', value: 'plaatmateriaal' },
//           { count: 1, data: {}, displayName: 'picknicktafel', status: '', value: 'picknicktafel' },
//           { count: 1, data: {}, displayName: 'picknick', status: '', value: 'picknick' },
//           { count: 1, data: {}, displayName: 'petunia', status: '', value: 'petunia' },
//           { count: 1, data: {}, displayName: 'petroleumkachel', status: '', value: 'petroleumkachel' },
//           { count: 1, data: {}, displayName: 'pergola', status: '', value: 'pergola' },
//           { count: 1, data: {}, displayName: 'palm', status: '', value: 'palm' },
//           { count: 1, data: {}, displayName: 'palen', status: '', value: 'palen' },
//           { count: 1, data: {}, displayName: 'osb', status: '', value: 'osb' },
//           { count: 1, data: {}, displayName: 'ondervloeren', status: '', value: 'ondervloeren' },
//           { count: 1, data: {}, displayName: 'ondervloer', status: '', value: 'ondervloer' },
//           { count: 1, data: {}, displayName: 'onderkast', status: '', value: 'onderkast' },
//           { count: 1, data: {}, displayName: 'olijfboom', status: '', value: 'olijfboom' },
//           { count: 1, data: {}, displayName: 'natuursteen', status: '', value: 'natuursteen' },
//           { count: 1, data: {}, displayName: 'muur isoleren', status: '', value: 'muur isoleren' },
//           { count: 1, data: {}, displayName: 'muur', status: '', value: 'muur' },
//           { count: 1, data: {}, displayName: 'muren', status: '', value: 'muren' },
//           { count: 1, data: {}, displayName: 'multitool', status: '', value: 'multitool' },
//           { count: 1, data: {}, displayName: 'multischuurmachine', status: '', value: 'multischuurmachine' },
//           { count: 1, data: {}, displayName: 'multiplex', status: '', value: 'multiplex' },
//           { count: 1, data: {}, displayName: 'mozaïektegel', status: '', value: 'mozaïektegel' },
//           { count: 1, data: {}, displayName: 'mozaik', status: '', value: 'mozaik' },
//           { count: 1, data: {}, displayName: 'mobiele airco', status: '', value: 'mobiele airco' },
//           { count: 1, data: {}, displayName: 'meubelpaneel', status: '', value: 'meubelpaneel' },
//           { count: 1, data: {}, displayName: 'metaalverf', status: '', value: 'metaalverf' },
//           { count: 1, data: {}, displayName: 'metaal verf', status: '', value: 'metaal verf' },
//           { count: 1, data: {}, displayName: 'metaal', status: '', value: 'metaal' },
//           { count: 1, data: {}, displayName: 'mdf', status: '', value: 'mdf' },
//           { count: 1, data: {}, displayName: 'margriet', status: '', value: 'margriet' },
//           { count: 1, data: {}, displayName: 'maaier', status: '', value: 'maaier' },
//           { count: 1, data: {}, displayName: 'luxaflex', status: '', value: 'luxaflex' },
//           { count: 1, data: {}, displayName: 'loungeset balkon', status: '', value: 'loungeset balkon' },
//           { count: 1, data: {}, displayName: 'loungebank', status: '', value: 'loungebank' },
//           { count: 1, data: {}, displayName: 'lijm', status: '', value: 'lijm' },
//           { count: 1, data: {}, displayName: 'ligbed', status: '', value: 'ligbed' },
//           { count: 1, data: {}, displayName: 'ligbank', status: '', value: 'ligbank' },
//           { count: 1, data: {}, displayName: 'lichtschakelaar', status: '', value: 'lichtschakelaar' },
//           { count: 1, data: {}, displayName: 'ledlamp', status: '', value: 'ledlamp' },
//           { count: 1, data: {}, displayName: 'layz spa', status: '', value: 'layz spa' },
//           { count: 1, data: {}, displayName: 'lay z spa', status: '', value: 'lay z spa' },
//           { count: 1, data: {}, displayName: 'lavendel', status: '', value: 'lavendel' },
//           { count: 1, data: {}, displayName: 'lampen', status: '', value: 'lampen' },
//           { count: 1, data: {}, displayName: 'lamp', status: '', value: 'lamp' },
//           { count: 1, data: {}, displayName: 'laminaat leggen', status: '', value: 'laminaat leggen' },
//           { count: 1, data: {}, displayName: 'lamellen', status: '', value: 'lamellen' },
//           { count: 1, data: {}, displayName: 'lakken', status: '', value: 'lakken' },
//           { count: 1, data: {}, displayName: 'lak', status: '', value: 'lak' },
//           { count: 1, data: {}, displayName: 'kwasten', status: '', value: 'kwasten' },
//           { count: 1, data: {}, displayName: 'kwast', status: '', value: 'kwast' },
//           { count: 1, data: {}, displayName: 'kunststof', status: '', value: 'kunststof' },
//           { count: 1, data: {}, displayName: 'krijt', status: '', value: 'krijt' },
//           { count: 1, data: {}, displayName: 'kranen', status: '', value: 'kranen' },
//           { count: 1, data: {}, displayName: 'kolomkast', status: '', value: 'kolomkast' },
//           { count: 1, data: {}, displayName: 'klopboor', status: '', value: 'klopboor' },
//           { count: 1, data: {}, displayName: 'kliksysteem', status: '', value: 'kliksysteem' },
//           { count: 1, data: {}, displayName: 'klassieke deur', status: '', value: 'klassieke deur' },
//           { count: 1, data: {}, displayName: 'kitten', status: '', value: 'kitten' },
//           { count: 1, data: {}, displayName: 'keuken', status: '', value: 'keuken' },
//           { count: 1, data: {}, displayName: 'kettingzaag', status: '', value: 'kettingzaag' },
//           { count: 1, data: {}, displayName: 'keramisch', status: '', value: 'keramisch' },
//           { count: 1, data: {}, displayName: 'keramiek', status: '', value: 'keramiek' },
//           { count: 1, data: {}, displayName: 'karton', status: '', value: 'karton' },
//           { count: 1, data: {}, displayName: 'kamerplanten', status: '', value: 'kamerplanten' },
//           { count: 1, data: {}, displayName: 'kamerplant', status: '', value: 'kamerplant' },
//           { count: 1, data: {}, displayName: 'kamer', status: '', value: 'kamer' },
//           { count: 1, data: {}, displayName: 'kachelbrandstof', status: '', value: 'kachelbrandstof' },
//           { count: 1, data: {}, displayName: 'kachel op brandstof', status: '', value: 'kachel op brandstof' },
//           { count: 1, data: {}, displayName: 'jaloezieën', status: '', value: 'jaloezieën' },
//           { count: 1, data: {}, displayName: 'isoleren', status: '', value: 'isoleren' },
//           { count: 1, data: {}, displayName: 'isolatieschui,', status: '', value: 'isolatieschui,' },
//           { count: 1, data: {}, displayName: 'isolatieplaten', status: '', value: 'isolatieplaten' },
//           { count: 1, data: {}, displayName: 'isolatieplaat', status: '', value: 'isolatieplaat' },
//           { count: 1, data: {}, displayName: 'isolatiemateriaal', status: '', value: 'isolatiemateriaal' },
//           { count: 1, data: {}, displayName: 'isolatiefolie', status: '', value: 'isolatiefolie' },
//           { count: 1, data: {}, displayName: 'isolatie', status: '', value: 'isolatie' },
//           { count: 1, data: {}, displayName: 'inzethor', status: '', value: 'inzethor' },
//           { count: 1, data: {}, displayName: 'invalzaag', status: '', value: 'invalzaag' },
//           { count: 1, data: {}, displayName: 'infraroodpaneel', status: '', value: 'infraroodpaneel' },
//           { count: 1, data: {}, displayName: 'infraroodkachel', status: '', value: 'infraroodkachel' },
//           { count: 1, data: {}, displayName: 'inbouw douche', status: '', value: 'inbouw douche' },
//           { count: 1, data: {}, displayName: 'houtskool', status: '', value: 'houtskool' },
//           { count: 1, data: {}, displayName: 'houten tuinmeubel', status: '', value: 'houten tuinmeubel' },
//           { count: 1, data: {}, displayName: 'houten deur', status: '', value: 'houten deur' },
//           { count: 1, data: {}, displayName: 'hout pvc', status: '', value: 'hout pvc' },
//           { count: 1, data: {}, displayName: 'hout beitsen', status: '', value: 'hout beitsen' },
//           { count: 1, data: {}, displayName: 'hortensia', status: '', value: 'hortensia' },
//           { count: 1, data: {}, displayName: 'horraam', status: '', value: 'horraam' },
//           { count: 1, data: {}, displayName: 'horgordijn', status: '', value: 'horgordijn' },
//           { count: 1, data: {}, displayName: 'hordeur', status: '', value: 'hordeur' },
//           { count: 1, data: {}, displayName: 'heester', status: '', value: 'heester' },
//           { count: 1, data: {}, displayName: 'hardhout', status: '', value: 'hardhout' },
//           { count: 1, data: {}, displayName: 'hardboard', status: '', value: 'hardboard' },
//           { count: 1, data: {}, displayName: 'hanglamp', status: '', value: 'hanglamp' },
//           { count: 1, data: {}, displayName: 'handpalmschuurmachine', status: '', value: 'handpalmschuurmachine' },
//           { count: 1, data: {}, displayName: 'handpalm', status: '', value: 'handpalm' },
//           { count: 1, data: {}, displayName: 'handgrasmaaier', status: '', value: 'handgrasmaaier' },
//           { count: 1, data: {}, displayName: 'halogeenlamp', status: '', value: 'halogeenlamp' },
//           { count: 1, data: {}, displayName: 'grondverf', status: '', value: 'grondverf' },
//           { count: 1, data: {}, displayName: 'grondlaag', status: '', value: 'grondlaag' },
//           { count: 1, data: {}, displayName: 'groene verf', status: '', value: 'groene verf' },
//           { count: 1, data: {}, displayName: 'groene muurverf', status: '', value: 'groene muurverf' },
//           { count: 1, data: {}, displayName: 'groene muur', status: '', value: 'groene muur' },
//           { count: 1, data: {}, displayName: 'grendel', status: '', value: 'grendel' },
//           { count: 1, data: {}, displayName: 'grasveld', status: '', value: 'grasveld' },
//           { count: 1, data: {}, displayName: 'grasmaaier', status: '', value: 'grasmaaier' },
//           { count: 1, data: {}, displayName: 'grasmaaien', status: '', value: 'grasmaaien' },
//           { count: 1, data: {}, displayName: 'gras', status: '', value: 'gras' },
//           { count: 1, data: {}, displayName: 'gordijn', status: '', value: 'gordijn' },
//           { count: 1, data: {}, displayName: 'gloeilamp', status: '', value: 'gloeilamp' },
//           { count: 1, data: {}, displayName: 'geranium', status: '', value: 'geranium' },
//           { count: 1, data: {}, displayName: 'geleiderail', status: '', value: 'geleiderail' },
//           { count: 1, data: {}, displayName: 'geleider', status: '', value: 'geleider' },
//           { count: 1, data: {}, displayName: 'gebroken wit', status: '', value: 'gebroken wit' },
//           { count: 1, data: {}, displayName: 'gazon', status: '', value: 'gazon' },
//           { count: 1, data: {}, displayName: 'gaskachel', status: '', value: 'gaskachel' },
//           { count: 1, data: {}, displayName: 'folie', status: '', value: 'folie' },
//           { count: 1, data: {}, displayName: 'flexa muurverf', status: '', value: 'flexa muurverf' },
//           {
//             count: 1,
//             data: {},
//             displayName: 'excentrische schuurmachine',
//             status: '',
//             value: 'excentrische schuurmachine'
//           },
//           { count: 1, data: {}, displayName: 'eps', status: '', value: 'eps' },
//           { count: 1, data: {}, displayName: 'elektrische kachel', status: '', value: 'elektrische kachel' },
//           { count: 1, data: {}, displayName: 'elektrische grasmaaier', status: '', value: 'elektrische grasmaaier' },
//           { count: 1, data: {}, displayName: 'elektra', status: '', value: 'elektra' },
//           { count: 1, data: {}, displayName: 'electrische kachel', status: '', value: 'electrische kachel' },
//           { count: 1, data: {}, displayName: 'electra', status: '', value: 'electra' },
//           { count: 1, data: {}, displayName: 'eiken', status: '', value: 'eiken' },
//           { count: 1, data: {}, displayName: 'duurzaam hout', status: '', value: 'duurzaam hout' },
//           { count: 1, data: {}, displayName: 'douglashout', status: '', value: 'douglashout' },
//           { count: 1, data: {}, displayName: 'douchestang', status: '', value: 'douchestang' },
//           { count: 1, data: {}, displayName: 'doucheset zwart', status: '', value: 'doucheset zwart' },
//           { count: 1, data: {}, displayName: 'doucheset zilver', status: '', value: 'doucheset zilver' },
//           { count: 1, data: {}, displayName: 'doucheset goud', status: '', value: 'doucheset goud' },
//           { count: 1, data: {}, displayName: 'doucheset', status: '', value: 'doucheset' },
//           { count: 1, data: {}, displayName: 'dimmer', status: '', value: 'dimmer' },
//           { count: 1, data: {}, displayName: 'deurdranger', status: '', value: 'deurdranger' },
//           { count: 1, data: {}, displayName: 'deltaschuurmachine', status: '', value: 'deltaschuurmachine' },
//           { count: 1, data: {}, displayName: 'decoupeerzaag', status: '', value: 'decoupeerzaag' },
//           { count: 1, data: {}, displayName: 'dakraam', status: '', value: 'dakraam' },
//           { count: 1, data: {}, displayName: 'dak isoleren', status: '', value: 'dak isoleren' },
//           { count: 1, data: {}, displayName: 'convector', status: '', value: 'convector' },
//           { count: 1, data: {}, displayName: 'cirkelzaag', status: '', value: 'cirkelzaag' },
//           { count: 1, data: {}, displayName: 'buizen', status: '', value: 'buizen' },
//           { count: 1, data: {}, displayName: 'buitenkeuken', status: '', value: 'buitenkeuken' },
//           { count: 1, data: {}, displayName: 'buiten verven', status: '', value: 'buiten verven' },
//           { count: 1, data: {}, displayName: 'buiten lakken', status: '', value: 'buiten lakken' },
//           { count: 1, data: {}, displayName: 'buisisolatie', status: '', value: 'buisisolatie' },
//           { count: 1, data: {}, displayName: 'bubbelbad', status: '', value: 'bubbelbad' },
//           { count: 1, data: {}, displayName: 'briquettes', status: '', value: 'briquettes' },
//           { count: 1, data: {}, displayName: 'briketten', status: '', value: 'briketten' },
//           { count: 1, data: {}, displayName: 'boren', status: '', value: 'boren' },
//           { count: 1, data: {}, displayName: 'boormachines', status: '', value: 'boormachines' },
//           { count: 1, data: {}, displayName: 'boormachine', status: '', value: 'boormachine' },
//           { count: 1, data: {}, displayName: 'boorhamer', status: '', value: 'boorhamer' },
//           { count: 1, data: {}, displayName: 'blokhut', status: '', value: 'blokhut' },
//           { count: 1, data: {}, displayName: 'bladblazers', status: '', value: 'bladblazers' },
//           { count: 1, data: {}, displayName: 'bladblazer', status: '', value: 'bladblazer' },
//           { count: 1, data: {}, displayName: 'binnenverlichting', status: '', value: 'binnenverlichting' },
//           { count: 1, data: {}, displayName: 'binnendeur', status: '', value: 'binnendeur' },
//           { count: 1, data: {}, displayName: 'bijverwarming', status: '', value: 'bijverwarming' },
//           { count: 1, data: {}, displayName: 'beton pvc', status: '', value: 'beton pvc' },
//           { count: 1, data: {}, displayName: 'beslag', status: '', value: 'beslag' },
//           { count: 1, data: {}, displayName: 'beitsverf', status: '', value: 'beitsverf' },
//           { count: 1, data: {}, displayName: 'behang', status: '', value: 'behang' },
//           { count: 1, data: {}, displayName: 'bbq', status: '', value: 'bbq' },
//           { count: 1, data: {}, displayName: 'barbecueën', status: '', value: 'barbecueën' },
//           { count: 1, data: {}, displayName: 'barbecue', status: '', value: 'barbecue' },
//           { count: 1, data: {}, displayName: 'balkon', status: '', value: 'balkon' },
//           { count: 1, data: {}, displayName: 'balken', status: '', value: 'balken' },
//           { count: 1, data: {}, displayName: 'badkamermeubel', status: '', value: 'badkamermeubel' },
//           { count: 1, data: {}, displayName: 'badkamer meubel', status: '', value: 'badkamer meubel' },
//           { count: 1, data: {}, displayName: 'airco zonder slang', status: '', value: 'airco zonder slang' },
//           { count: 1, data: {}, displayName: 'afkitten', status: '', value: 'afkitten' },
//           { count: 1, data: {}, displayName: 'achterdeur', status: '', value: 'achterdeur' },
//           { count: 1, data: {}, displayName: 'accuboormachine', status: '', value: 'accuboormachine' },
//           { count: 1, data: {}, displayName: 'accu', status: '', value: 'accu' },
//           { count: 1, data: {}, displayName: '9016', status: '', value: '9016' },
//           { count: 1, data: {}, displayName: '9010', status: '', value: '9010' },
//           { count: 1, data: {}, displayName: '9001', status: '', value: '9001' }
//         ],
//         type: 'multiple',
//         data: {},
//         hidden: false
//       }
//     ],
//     groups: [],
//     results: [
//       {
//         value: 'Productadvies steigerhout',
//         data: {
//           description:
//             'Ga je iets maken van steigerhout? Wij vertellen welke soorten steigerhout er zijn. En waar je op moet letten bij het kopen.  Zo kies je altijd het juiste steigerhout voor jouw klus!',
//           id: 'e9d90931-014a-42dc-9916-b9e46422e92c',
//           url: 'https://www.acc.praxis.nl/productadvies/steigerhout',
//           imageUrl:
//             'https://images.ctfassets.net/0nvfg2ncafkj/2tKfYTUmBF71FYfBNZv4eE/9411794f073a697d7bb5b680b7c15d91/loungebank_met_steigerhout.jpg',
//           metadata: { character: 'cover', publishDateTime: '20230501143155', type: 'product-advice' }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'Productadvies airco',
//         data: {
//           description:
//             'Bedenk voordat je een airco koopt, wat je met de airco wil verkoelen. Een volledige woning koelen doe je makkelijker met een split airco. Een mobiele airco is heel geschikt om één ruimte mee te verkoelen. Wij leggen de verschillen uit en helpen je ...',
//           id: 'de99daba-d25b-4c65-bee8-ac40419b6372',
//           url: 'https://www.acc.praxis.nl/productadvies/airco',
//           metadata: { publishDateTime: '20230501143103', type: 'product-advice' }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'Productadvies tuinhout',
//         data: {
//           description:
//             'Aan de slag met hout in je tuin? Wij leggen je uit welke soorten tuinhout er zijn, waarvoor je welk hout kan gebruiken en waar je op moet letten bij het kopen van tuinhout.  Zo kies je altijd het juiste hout voor je klus!',
//           id: '3d720ef4-2ae6-46eb-a123-a6cf6a6d63a9',
//           url: 'https://www.acc.praxis.nl/productadvies/tuinhout',
//           imageUrl:
//             'https://images.ctfassets.net/0nvfg2ncafkj/49fg4zgjUj811haSZZS3ry/08fa4d1600c9261b3b30fa88b4afc1db/schuttingentuinhout940x940.jpg',
//           metadata: { character: 'cover', publishDateTime: '20230501142947', type: 'product-advice' }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'Productadvies blokhutten',
//         data: {
//           description:
//             'Maak je tuin helemaal compleet met een mooi tuinhuisje. Wij leggen je uit welke verschillende soorten tuinhuisjes er zijn. En waar je op moet letten bij je aankoop.',
//           id: 'a39121ec-9b8b-4461-83e5-7de22ac242c3',
//           url: 'https://www.acc.praxis.nl/productadvies/blokhutten-tuinhuisjes',
//           imageUrl:
//             'https://images.ctfassets.net/0nvfg2ncafkj/5BtAlW6JjdMWPxqzlRWqRJ/1afd26ad683920d0ad0ab43e99f554c2/blokhut_tuin.jpg',
//           metadata: { character: 'cover', publishDateTime: '20230501142931', type: 'product-advice' }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'Productadvies grasmaaier',
//         data: {
//           description:
//             'Ben je op zoek naar een geschikte grasmaaier voor jouw tuin? Wij leggen je uit welke verschillende grasmaaiers we hebben en wat de voor- en nadelen zijn per soort.  Zo kies je altijd de juiste grasmaaier!',
//           id: '6777b8e7-83a2-4769-aaec-d6ca91ad2c4b',
//           url: 'https://www.acc.praxis.nl/productadvies/grasmaaier',
//           imageUrl:
//             'https://images.ctfassets.net/0nvfg2ncafkj/7ko8ubOjHqnII51HgqJFzI/7eeb303d23446a6ae9d8edca884b5744/grasmaaier_bosch.jpg',
//           metadata: { character: 'cover', publishDateTime: '20230501142900', type: 'product-advice' }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'Productadvies PVC vloeren',
//         data: {
//           description:
//             'PVC vloeren worden steeds populairder. Niet gek, gezien de kwaliteiten die deze vloer kent. Wij leggen uit wat de voor- en nadelen zijn en waar je op moet letten bij het kopen van een PVC vloer.',
//           id: '2ac1bc08-54f2-47fc-871e-3f23983ab425',
//           url: 'https://www.acc.praxis.nl/productadvies/pvc-vloeren',
//           imageUrl:
//             'https://images.ctfassets.net/0nvfg2ncafkj/1E0LUUI0ksJAMlcO9zfqGR/2f80dbc701d34ea7bfd8220ad490ad3a/pvc-vloeren940x940.jpg',
//           metadata: { character: 'cover', publishDateTime: '20230501142804', type: 'product-advice' }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'Productadvies schakelmateriaal',
//         data: {
//           description:
//             'Ben je op zoek naar een stopcontact, schakelaar of dimmer? Wij helpen je verder en vertellen je welke soorten schakelmateriaal er zijn en waar je op moet letten.',
//           id: '924f39d4-1cb2-4fe3-a70a-a9380de42aec',
//           url: 'https://www.acc.praxis.nl/productadvies/schakelmateriaal',
//           imageUrl:
//             'https://images.ctfassets.net/0nvfg2ncafkj/991a8e12-3c57-4770-add9-8d82b9671977/d3b3e915f9779521dc01f8715eefdc11/stopcontact.jpg',
//           metadata: { character: 'cover', publishDateTime: '20230501142748', type: 'product-advice' }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'Productadvies krijtverf',
//         data: {
//           description:
//             'Met krijtverf geef je hout een matte, poederachtige uitstraling. De verf wordt vaak verward met schoolbordverf of kalkverf, maar is niet hetzelfde. In dit advies leggen wij uit wat krijtverf is, hoe je het aanbrengt en wat je nodig hebt om aan de ...',
//           id: '3GUzQcR3sOGOIDqqPVIVdG',
//           url: 'https://www.acc.praxis.nl/productadvies/krijtverf',
//           imageUrl:
//             'https://images.ctfassets.net/0nvfg2ncafkj/GRVAb43weCSvBD1CHTXvu/a3c5c8face9c31ad18e2e7fbc14e3266/krijtverf.jpg',
//           metadata: { character: 'cover', publishDateTime: '20230501142733', type: 'product-advice' }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'Productadvies beitsen',
//         data: {
//           description:
//             'Beitsen is een manier om je tuinhout te beschermen tegen weersinvloeden. Voor je begint, zijn er altijd wat zaken om rekening mee te houden. Welke soorten beits zijn er? Hoe bereid je de klus voor? Ook geven we hier wat meer informatie omtrent de ...',
//           id: '35a36ff3-2768-4969-8fef-1cb866e13e75',
//           url: 'https://www.acc.praxis.nl/productadvies/beitsen',
//           imageUrl:
//             'https://images.ctfassets.net/0nvfg2ncafkj/6L5izE7uskHksno60LouOz/bbbfd231bf2585dfbbd574307df219cc/beitsen.jpg',
//           metadata: { character: 'cover', publishDateTime: '20230501142644', type: 'product-advice' }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'Productadvies gebroken wit verf',
//         data: {
//           description:
//             'Ben je op zoek naar de perfecte tint wit om je slaapkamer een fris en toch warm karakter mee te geven? Denk dan eens aan de verfkleur gebroken wit! Wil je weten hoe de kleur gebroken wit er precies uitziet, welke verf Praxis in deze tint in het ...',
//           id: '5tkW1MYsjXuGMthuiqclzQ',
//           url: 'https://www.acc.praxis.nl/productadvies/verf-gebroken-wit',
//           imageUrl:
//             'https://images.ctfassets.net/0nvfg2ncafkj/5AMQWnmeYTA19QanqerOaH/48eba613a64d4f3ed645e7f4859cccc6/radiatorombouw.jpg',
//           metadata: { character: 'cover', publishDateTime: '20230501142626', type: 'product-advice' }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'Productadvies luchtkoelers',
//         data: {
//           description:
//             'Wat extra verkoeling in huis in de zomer is geen overbodige luxe! Met een luchtkoeler maak je de temperatuur in huis gemakkelijk wat aangenamer. Maar hoe werkt zo’n luchtkoeler precies, wat is het verschil tussen een luchtkoeler en een airco en waar ...',
//           id: '1HwN0BRQiPsplsl2SZNOrf',
//           url: 'https://www.acc.praxis.nl/productadvies/luchtkoelers',
//           imageUrl:
//             'https://images.ctfassets.net/0nvfg2ncafkj/6AZJdjHUA49qXBbZpbfQkM/0edde503f7638a01a01b3406dfddae07/luchtkoeler_h.jpg',
//           metadata: { character: 'cover', publishDateTime: '20230501142610', type: 'product-advice' }
//         },
//         matchedTerms: [],
//         labels: {}
//       },
//       {
//         value: 'RAL 9001: alles over deze witte verfkleur',
//         data: {
//           description:
//             'Wil je dolgraag je huis een wat warmere uitstraling geven? Of zoek je naar de perfecte witte kleur om de buitenkant van je huis mee te schilderen? Dan is RAL 9001 de kleur waarnaar je zoekt! Wil je weten hoe RAL 9001 er precies uitziet, wat deze ...',
//           id: '2NlQksnZKA1fjTgP4I04jC',
//           url: 'https://www.acc.praxis.nl/productadvies/ral-9001',
//           imageUrl:
//             'https://images.ctfassets.net/0nvfg2ncafkj/4q4cMdd4QVx5neUdLnQeFX/54131f968a6c5791792739e16a8612cf/header_ral_9001.jpg',
//           metadata: { character: 'cover', publishDateTime: '20230501142512', type: 'product-advice' }
//         },
//         matchedTerms: [],
//         labels: {}
//       }
//     ],
//     totalNumberOfResults: 51,
//     sortOptions: [
//       { displayName: 'Most Relevant', sortBy: 'relevance', sortOrder: 'descending', status: '' },
//       { displayName: 'Nouveautés', sortBy: 'publishDateTime', sortOrder: 'descending', status: 'selected' }
//     ],
//     resultSources: { tokenMatch: { count: 51 }, embeddingsMatch: { count: 0 } },
//     refinedContent: []
//   },
//   request: {
//     page: 2,
//     num_results_per_page: 12,
//     sort_by: 'publishDateTime',
//     sort_order: 'descending',
//     filters: {
//       valid: ['1'],
//       pageType: ['diy-tip', 'blog', 'step-by-step', 'product-advice'],
//       tags: ['Product advice', 'DIY-tip', 'Step-by-Step', 'Blog page type']
//     },
//     section: 'Soft_Content',
//     term: '',
//     fmt_options: {
//       groups_start: 'current',
//       groups_max_depth: 1,
//       show_hidden_facets: false,
//       show_hidden_fields: false,
//       show_protected_facets: false
//     },
//     features: {
//       query_items: true,
//       a_a_test: true,
//       auto_generated_refined_query_rules: true,
//       manual_searchandizing: true,
//       personalization: true,
//       filter_items: true,
//       use_reranker_service_for_search: false,
//       use_reranker_service_for_browse: false,
//       use_reranker_service_for_all: false,
//       custom_autosuggest_ui: false,
//       disable_test_only_global_rules_search: false,
//       disable_test_only_global_rules_browse: false,
//       use_enriched_attributes_as_fuzzy_searchable: false
//     },
//     feature_variants: {
//       query_items: 'query_items_ctr_and_l2r',
//       auto_generated_refined_query_rules: 'default_rules',
//       personalization: 'default_personalization',
//       filter_items: 'filter_items_w_atcs_and_purchases'
//     },
//     filter_match_types: { valid: 'any', pageType: 'any', tags: 'any' },
//     searchandized_items: {},
//     browse_filter_name: 'valid',
//     browse_filter_value: '1'
//   }
// }
