import React from 'react'
import { Box, Flex, Button, CrossIcon, MOBILE, Text } from 'chemistry'

const PopularSearchTerms = ({ contentfulTranslations, items, selectedValue, selectHandler }) => {
  const onClose = e => {
    e.stopPropagation()
    selectHandler('')
  }

  const handlePopularSelect = value => {
    selectHandler(value)
  }

  return (
    <Flex
      sx={{
        marginTop: 'sm',
        marginBottom: '-sx',
        flexWrap: 'wrap',
        alignItems: 'center',
        width: '100%',
        [MOBILE]: {
          flexDirection: 'column',
          alignItems: 'start'
        }
      }}
    >
      <Box mr='sm' mb='sx'>
        {contentfulTranslations.popularSearch}
      </Box>

      <Flex flexWrap='wrap'>
        {items.map((item, index) => {
          return (
            <Button
              variant='secondarySmall'
              onClick={() => handlePopularSelect(item)}
              key={`quick-actions-${index}`}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginRight: 'sx',
                marginBottom: 'sx',
                borderColor: selectedValue === item ? 'red.700' : '',
                letterSpacing: '0.75px'
              }}
            >
              {selectedValue === item && (
                <CrossIcon
                  onClick={e => onClose(e)}
                  data-testid='cross-icon'
                  sx={{
                    color: 'red.700',
                    marginRight: '7px',
                    marginLeft: '-4xs',
                    width: '5',
                    height: '5'
                  }}
                />
              )}
              <Text fontFamily='bold'>{item}</Text>
            </Button>
          )
        })}
      </Flex>
    </Flex>
  )
}

export default PopularSearchTerms
