import React from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import { onClickHandler } from './PageButton'

export const PrevButton = ({ link = '#', caption = 'Prev', onClick }) => {
  return (
    <a href={link} className='flex items-center gap-half' rel='prev' onClick={e => onClickHandler(e, onClick)}>
      <FaChevronLeft fontSize='0.7em' />
      <span className=''>{caption}</span>
    </a>
  )
}
