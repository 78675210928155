import { MAXIMUM_VIEW_SIZE } from 'common/constants'

export const getPaginationProps = ({ page, quantity, t, onPageChange, term, viewSize = MAXIMUM_VIEW_SIZE }) => ({
  currentPage: page?.page,
  total: Math.ceil(quantity / viewSize),
  translations: {
    prevButtonCaption: t('lister.pager.prev'),
    nextButtonCaption: t('lister.pager.next')
  },
  onPageChange,
  getPath: pageNum => {
    const searchParams = new URLSearchParams()
    if (pageNum) searchParams.append('currentPage', pageNum)
    if (term) searchParams.append('term', term)

    if (isFirstPageWithNoParams(pageNum, searchParams)) return '?'
    return `?${searchParams.toString()}`
  },
  keyword: 'currentPage'
})

export const isFirstPageWithNoParams = (pageNum, searchParams) =>
  pageNum === 1 && [...searchParams.values()]?.length === 1 && !!searchParams.get('currentPage')
