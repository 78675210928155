import React from 'react'
import { scrollToTop } from 'chemistry'

export const onClickHandler = (event, callback) => {
  event.preventDefault()
  scrollToTop(200)
  callback?.()
}

export const PageButton = ({ number, link = '#', isCurrent, onClick }) => {
  if (isCurrent) return <strong rel='current'>{number}</strong>

  return (
    <a href={link} onClick={e => onClickHandler(e, () => onClick(number))}>
      {number}
    </a>
  )
}
