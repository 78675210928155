/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react'
import SearchInput from '../../../../common/components/Search/SearchInput/SearchInput'
import classNames from 'classnames'

const ENTER = 13

const SearchInputContainer = ({ format, lang, placeholderText, t, handleSearchSubmit }) => {
  const [focused, setFocused] = useState(false)
  const [term, setTerm] = useState('')

  const root = useRef()
  const input = useRef()
  const form = useRef()
  const pageModeInput = useRef()

  let params
  if (global.document?.location.href) {
    const { searchParams } = new URL(global.document?.location.href)
    params = searchParams
  }

  useEffect(() => {
    setTerm(params.get('term') ?? '')
  }, [global.document?.location.href])

  const fullClear = () => {
    setFocused(false)
    setTerm('')
    input.current?.blur()
    if (term && !!params.get('term')) {
      handleSearchSubmit('')
    }
  }

  const handleSubmit = e => {
    if (!term) return
    e.preventDefault()
    handleSearchSubmit(term)
  }

  const handleKeyDown = e => {
    if (e.keyCode === ENTER) {
      handleSubmit(e)
    }
  }

  return (
    <div
      className={classNames({
        'sfe-search': true,
        'sfe-search-initial': !focused
      })}
      ref={root}
      onKeyDown={handleKeyDown}
    >
      <SearchInput
        inputRef={input}
        formRef={form}
        pageModeRef={pageModeInput}
        value={term}
        placeholder={placeholderText}
        name='term'
        focused={focused}
        lang={lang}
        format={format}
        onFocus={() => setFocused(true)}
        onClear={() => fullClear()}
        onBlur={() => setFocused(false)}
        onChange={event => {
          const termNew = event.target.value
          setTerm(termNew)
        }}
        onClickSearchIcon={handleSubmit}
        t={t}
      />
    </div>
  )
}

export default SearchInputContainer
