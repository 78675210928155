import { getLocale, properProductCode } from 'client/common/utils'
import { createLogger } from 'common/log'
import { OVERVIEW_FILTER_TYPE, OVERVIEW_FILTER_TAG_TYPE, OVERVIEW_FILTER_VALID_TYPE } from 'common/constants'

const SEARCH_SERVICE_SUFFIX = '/search-service'
const defaultUrlValues = { format: 'praxis', lang: 'nl' }
const getBasePath = ({ format = defaultUrlValues.format, lang = defaultUrlValues.lang } = defaultUrlValues) =>
  `${SEARCH_SERVICE_SUFFIX}/rest/v3/${format}/${lang}`

const log = createLogger('fragments/cross-sell/gateway')
// /search-service/rest/v3/products/{formula}/{lang}/crossSell/?locale={locale}&formula={formula}&id=id&userId=admin
export const getCrossSellProductsPath = ({ userId, productCode, lang, format, type, viewSize }) => {
  const id = productCode ? properProductCode(productCode) : undefined

  const queryParams = Object.entries({ id, type, viewSize, userId })
    .filter(([key, val]) => val !== undefined && `${val}`.length > 0)
    .reduce((acc, [key, val]) => {
      acc[key] = val
      return acc
    }, {})

  const queryString = new URLSearchParams(queryParams).toString()
  const url = `${getBasePath({ format, lang })}/crossSell?${queryString}`
  log.debug(url)
  return url
}

// /search-service/rest/v3/praxis/nl/search/ids?ids%5B%5D=5663799&userId=admin
export const getByIdProductsUrl = ({ productCodes = [], userId, lang, format }) => {
  const queryParameters = productCodes.map(id => `ids%5B%5D=${id}`) // ids[]=id
  if (userId) {
    queryParameters.push(`userId=${userId}`)
  }
  const queryString = queryParameters.join('&')

  const url = `${getBasePath({ format, lang })}/search/ids?${queryString}`
  log.debug(url)
  return url
}

export const getCollectionProductsUrl = ({ collectionId, lang, format, viewSize = 12, page = 1 }) => {
  const url = `${getBasePath({
    format,
    lang
  })}/collection?collectionId=${collectionId}&viewSize=${viewSize}&page=${page}`
  log.debug(url)
  return url
}

const getCategoryCode = breadcrumbs => {
  const [, code] = breadcrumbs
    ?.filter(crumb => crumb?.categoryCode && !crumb?.isHome)
    .map(crumb => crumb?.categoryCode)
    .slice(0, 2)
  if (!code) log.error('Cannot define category code for serie')
  return code
}
const vocabulary = {
  nl_NL: 'Serie',
  nl_BE: 'Serie',
  fr_BE: 'Série'
}
// /search-service/rest/v3/praxis/nl/category?Serie=Aquanto&filterKeys=Serie&categoryCode=d271&viewSize=7'
export const getProductsSerieUrl = ({ lang, format, serie, breadcrumbs, viewSize = 7, userId }) => {
  const locale = getLocale(format, lang)
  const categoryCode = getCategoryCode(breadcrumbs)
  const keyWord = vocabulary[locale]

  const params = {
    [keyWord]: serie,
    filterKeys: keyWord,
    categoryCode,
    viewSize,
    userId
  }

  const queryParamsForSerie = Object.entries({ ...params })
    .filter(([key, val]) => !!val)
    .reduce((acc, [key, val]) => {
      acc[key] = val
      return acc
    }, {})

  const queryParamsForButtonHref = { ...queryParamsForSerie }
  delete queryParamsForButtonHref.viewSize
  delete queryParamsForButtonHref.categoryCode
  delete queryParamsForButtonHref.sessionId

  const queryStringForSerie = new URLSearchParams(queryParamsForSerie).toString()
  const queryStringForButtonHref = new URLSearchParams(queryParamsForButtonHref).toString()
  const path = breadcrumbs?.[2]?.path || breadcrumbs?.[1]?.path || '#'

  return {
    productsSerieUrl: `${getBasePath({ format, lang })}/category?${queryStringForSerie}`,
    buttonHref: `${path}?${queryStringForButtonHref}`
  }
}

export const getProductsByCategoryUrl = ({ category, lang, format, viewSize = 24, userId }) => {
  let url = `${getBasePath({ format, lang })}/category?categoryCode=${category}&viewSize=${viewSize}`
  if (userId) {
    url = `${url}&userId=${userId}`
  }
  log.debug(url)
  return url
}

// https://www.praxis.nl/search-service/rest/v3/praxis/nl/content?term=laminaat
export const getOverviewResults = ({
  lang,
  format,
  sortBy,
  term,
  viewSize,
  page,
  pageTypes,
  sortOrder,
  inclusionTags,
  exclusionTags
}) => {
  const queryParams = {
    viewSize,
    page,
    sortBy,
    sortOrder,
    filterKeys: `${OVERVIEW_FILTER_VALID_TYPE},${OVERVIEW_FILTER_TYPE}`,
    valid: 1
  }

  if (inclusionTags?.length) {
    queryParams.tags = inclusionTags
    queryParams.filterKeys += `,${OVERVIEW_FILTER_TAG_TYPE}`
  }

  if (pageTypes?.length > 0) {
    queryParams.pageType = pageTypes
  }

  if (exclusionTags?.length > 0) {
    const exTags = exclusionTags.filter(tag => !!tag).map(tag => `{"not":{"name":"tags","value":"${tag}"}}`)
    queryParams.pre_filter_expression = `{"and":[${exTags.toString()}]}`
  }

  if (term) {
    queryParams.term = term
  }
  const queryString = new URLSearchParams(queryParams).toString()

  return `${SEARCH_SERVICE_SUFFIX}/rest/v3/${format}/${lang}/content?${queryString}`
}
