const { NODE_ENV, LOG_LEVEL, LOG_INCOME_REQUEST } = process?.env || {}
const DEV = NODE_ENV && NODE_ENV !== 'production'

const pino = require('pino')

let config = {
  messageKey: 'message',
  level: 'info'
}

// IMPORTANT!!! When change this always test in IE 11, because it may affect it
if (DEV) {
  const configDev = {
    messageKey: 'msg',
    level: LOG_LEVEL || 'trace',
    prettyPrint: {},
    prettifier: require('pino-colada')
  }
  config = { ...config, ...configDev }
}

const loggerObj = pino(config)
const httpLoggerObj = (req, res, next) => {
  if (DEV && LOG_INCOME_REQUEST) loggerObj.debug(`${req.method} ${req.url}`)
  next()
}
const createLoggerObj = (name, props) => loggerObj.child(Object.assign({ module: name }, props))

export const httpLogger = httpLoggerObj
export const createLogger = createLoggerObj
