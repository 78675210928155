import React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { onClickHandler } from './PageButton'

export const NextButton = ({ link = '#', caption = 'Next', onClick }) => {
  return (
    <a href={link} className='flex items-center gap-half' rel='next' onClick={e => onClickHandler(e, onClick)}>
      <span className=''>{caption}</span>
      <FaChevronRight fontSize='0.7em' />
    </a>
  )
}
