import React from 'react'

import { PrevButton } from './PrevButton'
import { NextButton } from './NextButton'
import { Pages } from './Pages'
import { Divider } from 'client/common/components/Divider'

export const Pagination = ({
  isSoftContentOverview,
  currentPage,
  total,
  translations,
  getPath,
  visible,
  useActionProps,
  onPageChange
}) => {
  const handlerCommonProps = useActionProps()

  if (total <= 1 || !visible) return <div style={{ gridArea: 'pagination' }} />

  const onChange = isSoftContentOverview ? onPageChange : onPageChange(handlerCommonProps)
  const showPrevPage = currentPage > 1
  const showNextPage = currentPage !== total
  const { prevButtonCaption, nextButtonCaption } = translations

  return (
    <div className='mxd-pagination'>
      <Divider gridColumn='1/-1' />
      {showPrevPage && (
        <PrevButton
          link={getPath(currentPage - 1)}
          caption={prevButtonCaption}
          onClick={() => onChange(currentPage - 1)}
        />
      )}
      <Pages {...{ currentPage, getPath, onChange, total }} />
      {showNextPage && (
        <NextButton
          link={getPath(currentPage + 1)}
          caption={nextButtonCaption}
          onClick={() => onChange(currentPage + 1)}
        />
      )}
    </div>
  )
}
