import { GET_PAGES, SET_PAGES, SET_PAGES_PAGINATION } from './actions'

export const overviewReducer = (state, action) => {
  switch (action.type) {
    case GET_PAGES:
      return {
        ...state,
        page: action.page,
        totalNumberOfResults: 0,
        isLoading: true,
        isMainLoading: action.reset
      }
    case SET_PAGES:
      return {
        ...state,
        pages: action?.pages,
        totalNumberOfResults: action.totalNumberOfResults,
        page: action?.page,
        isLoading: false,
        isMainLoading: false
      }
    case SET_PAGES_PAGINATION:
      return {
        ...state,
        pages: action.reset ? action.pages || [] : [...(action.pages || [])],
        totalNumberOfResults: action.totalNumberOfResults,
        page: action?.page,
        isLoading: false,
        isMainLoading: false
      }
    default:
      return state
  }
}
