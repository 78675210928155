import React from 'react'
import { NO_IMAGE } from 'chemistry'

const ProductPlaceholder = ({ perRow = 4 }) => (
  <div className={`mxd-width-1-1 mxd-tiny-small-width-1-2 mxd-small-width-1-${perRow}`}>
    <div className='mxd-block-card mxd-block-card-responsive mxd-is-loading'>
      <div className='mxd-block-card-image'>
        <img className='mxd-fixed-ratio-image' alt='image-placeholder' title='image-placeholder' src={NO_IMAGE} />
      </div>
      <strong className='mxd-block-card-heading'>
        <span className='mxd-block-card-link'>
          &nbsp;
          <br />
          &nbsp;
        </span>
      </strong>
      <div className='mxd-price'>
        <ins>&nbsp;0.00</ins>
      </div>
      <div className='mxd-block-card-meta'>
        <span className='mxd-helper'>&nbsp;</span>
      </div>
    </div>
  </div>
)

export default ProductPlaceholder
