import React from 'react'
import ProductPlaceholder from './ProductPlaceholder'
import { Flex } from 'chemistry'

const ProductsPlaceholdersList = ({ count = 4, justItems = false, perRow = 4, crossSellRef }) => {
  const elements = [...Array(count)].map((_, i) => <ProductPlaceholder key={i} perRow={perRow} />)
  if (justItems) {
    return <>{elements}</>
  }
  return (
    <Flex flexWrap='wrap' ref={crossSellRef}>
      {elements}
    </Flex>
  )
}

export default ProductsPlaceholdersList
