import { initFragmentOnClient } from 'lib/ssr-client-react'
import fragment from './index'
import { createLogger } from 'common/log'

const logger = createLogger('fragments/softContentOverview/client')
const fragmentName = 'softContentOverview'

document.querySelectorAll('[data-soft-content-overview-marker]').forEach(function (node) {
  const id = node.closest('[id^=softContentOverview]').id
  initFragmentOnClient(fragment.Component, fragmentName, id)
})

// DO NOT CHANGE UNLESS YOU REALLY KNOW WHAT YOU ARE DOING
if (module.hot) {
  module.hot.accept('./index', () => {
    logger.debug(`hot reloading ${fragmentName}`)
    const reload = require('./index').default
    initFragmentOnClient(reload.Component, fragmentName)
  })
}
