import React from 'react'
import { PageButton } from './PageButton'

/**
 * @example
 * [1] 2 3 … 5  >
 * <  1 … 5 [6] 7 … 158  >
 * <  1 … 156 157 [158]
 */

const GAP = 2

export const Pages = ({ currentPage: current, getPath, onChange, total }) => {
  if (!total || total === 1) return null
  if (current > total) return null

  const pageFrom = Math.max(current - GAP, 2) // 2 because 1 is already rendered by default
  const pageTo = Math.min(current + GAP, total - 1)

  const pagesToRender = pageTo + 1 - pageFrom

  const shouldShowDots = total >= 5
  const isShowLeftDots = shouldShowDots && current - GAP > 0
  const isShowRightDots = shouldShowDots && total - current > GAP

  return (
    <ol className='flex items-center justify-center list-reset line-height-1'>
      <li aria-current={current === 1}>
        <PageButton number={1} isCurrent={current === 1} link={getPath(1)} onClick={onChange} />
      </li>
      {isShowLeftDots && (
        <li>
          <strong>...</strong>
        </li>
      )}
      {[...Array(pagesToRender)].map((_, i) => {
        const pageNum = pageFrom + i
        const isCurrent = current === pageNum
        return (
          <li key={pageNum} aria-current={isCurrent}>
            <PageButton number={pageNum} isCurrent={isCurrent} link={getPath(pageNum)} onClick={onChange} />
          </li>
        )
      })}
      {isShowRightDots && (
        <li>
          <strong>...</strong>
        </li>
      )}
      <li aria-current={current === total}>
        <PageButton number={total} isCurrent={current === total} link={getPath(total)} onClick={onChange} />
      </li>
    </ol>
  )
}
